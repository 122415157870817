import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as TaxIcon } from 'assets/icons/tax.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left.svg';
import { ReactComponent as FilledLeftArrow } from 'assets/icons/filled-arrow-left.svg';
import { ReactComponent as Hotline } from 'assets/icons/hotline.svg';
import { ReactComponent as CircleFacebook } from 'assets/icons/facebook-circle.svg';
import { ReactComponent as CircleInstagram } from 'assets/icons/instagram-circle.svg';

import { Typography, Col, Row, Space, Button, Divider } from 'antd';
import { WhatsAppOutlined, YoutubeFilled } from '@ant-design/icons';
import { isSignedInSelector } from 'redux/auth/selectors';
import './index.scss';

const Footer = () => {
  const { t } = useTranslation('footer');

  const isSignedIn = useSelector(isSignedInSelector);
  const mobileView = useMedia('(max-width: 576px)');

  return (
    <div className="site-footer">
      <div className="site-footer-header">
        <Row>
          {isSignedIn && (
            <Col className="account" xs={12} sm={8} md={5} lg={3} xl={3} xxl={3}>
              <Space direction="vertical">
                <div>{t('account')}</div>
                {[
                  { to: '/my-account/account', text: t('my-account') },
                  { to: '/my-account/orders', text: t('my-orders') },
                  { to: '/cart', text: t('cart') },
                  { to: '/my-account/wishlist', text: t('wishlist') },
                ].map(({ to, text }) => (
                  <Link key={to + text} to={to}>
                    {window.location.pathname === to ? <FilledLeftArrow /> : <LeftArrow />}
                    <Button className={`${window.location.pathname === to ? 'active' : ''}`} type="text">
                      {text}
                    </Button>
                  </Link>
                ))}
              </Space>
              <Divider type="vertical" />
            </Col>
          )}

          <Col className="links" xs={12} sm={8} md={5} lg={4} xl={4} xxl={4}>
            <Space direction="vertical">
              <div>{t('links')}</div>
              {[
                { to: '/about-us', text: t('about-us') },
                { to: '/privacy-policy', text: t('privacy-policy') },
                { to: '/terms-conditions', text: t('terms-conditions') },
              ].map(({ to, text }) => (
                <Link key={to + text} to={to}>
                  {window.location.pathname === to ? <FilledLeftArrow /> : <LeftArrow />}
                  <Button className={`${window.location.pathname === to ? 'active' : ''}`} type="text">
                    {text}
                  </Button>
                </Link>
              ))}
            </Space>
            <Divider type="vertical" />
          </Col>

          {/* <Col className="contact-us" xs={12} sm={8} md={5} lg={5} xl={5} xxl={5}>
            <Space direction="vertical">
              <div>{t('contact-us')}</div>
              {[
                { label: t('hotline'), text: t('hotline-number') },
                { label: t('whatsapp'), text: t('whatsapp-number') },
                { label: t('email'), text: t('email-address') },
              ].map(({ label, text }) => (
                <div className="contact-wrapper" key={label + text}>
                  <div>
                    <LeftArrow />
                  </div>
                  <div>
                    <Typography.Text>{label}</Typography.Text>
                    <Typography.Text>{text}</Typography.Text>
                  </div>
                </div>
              ))}
            </Space>
            <Divider type="vertical" />
          </Col> */}

          {!mobileView && (
            <Col className="follow-us" xs={12} sm={8} md={5} lg={4} xl={4} xxl={4}>
              <Space direction="vertical">
                <div>{t('follow-us')}</div>
                {[
                  // {
                  //   href: 'https://api.whatsapp.com/send?phone=201120777715',
                  //   icon: <WhatsAppOutlined />,
                  //   text: t('whatsapp'),
                  // },
                  { href: 'https://www.facebook.com/Abdulazizstores/', icon: <CircleFacebook />, text: t('facebook') },
                  {
                    href: 'https://www.instagram.com/abdulazizstores/',
                    icon: <CircleInstagram />,
                    text: t('instagram'),
                  },
                  {
                    href: 'https://www.youtube.com/channel/UCRd2YBDJG5Sq8zeg57jj22w',
                    icon: <YoutubeFilled />,
                    text: t('youtube'),
                  },
                ].map(({ href, icon, text }) => (
                  <div>
                    {icon}
                    <Button key={href + text} target="_blank" href={href} type="text">
                      {text}
                    </Button>
                  </div>
                ))}
              </Space>
            </Col>
          )}

          <Col
            className="hotline-wrapper"
            style={{ justifyContent: isSignedIn ? 'center' : 'end' }}
            xs={12}
            sm={8}
            md={4}
            lg={8}
            xl={8}
            xxl={8}
          >
            <Hotline />
            <div>
              <Typography.Text className="hotline">{t('hotline')}</Typography.Text>
              <Typography.Text className="hotline-number">16581</Typography.Text>
            </div>
          </Col>
        </Row>
      </div>
      {mobileView && (
        <div className="mobile-social-media">
          <Row>
            <Col className="follow-us" xs={24} sm={24} md={24} lg={24} xl={24}>
              {[
                // {
                //   href: 'https://api.whatsapp.com/send?phone=201120777715',
                //   icon: <WhatsAppOutlined />,
                //   text: t('whatsapp'),
                // },
                { href: 'https://www.facebook.com/Abdulazizstores/', icon: <CircleFacebook />, text: t('facebook') },
                {
                  href: 'https://www.instagram.com/abdulazizstores/',
                  icon: <CircleInstagram />,
                  text: t('instagram'),
                },
                {
                  href: 'https://www.youtube.com/channel/UCRd2YBDJG5Sq8zeg57jj22w',
                  icon: <YoutubeFilled />,
                  text: t('youtube'),
                },
              ].map(({ href, icon, text }) => (
                <div>
                  <Button key={href + text} target="_blank" href={href} type="text">
                    {icon}
                  </Button>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      )}
      <div className="site-footer-bottom">
        <Row align="center" justify="space-between" className="site-footer-bottom-wrapper">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Text>
              <Space className="tax-wrapper">
                <TaxIcon />
                <Typography.Text>
                  <Space size="small" wrap>
                    {`${t('tax')}:`}
                    <span>{t('tax-number')}</span>
                  </Space>
                </Typography.Text>
              </Space>
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row align="center" justify="end" className="copy-right">
              <Space size="small">
                <Typography.Text>{t('copy-rights')}</Typography.Text>
              </Space>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
