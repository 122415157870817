import Orders from 'redux/orders';
import Addresses from 'redux/addresses';
import { fetchById } from 'redux/addresses/thunks';
import { List, Space, Typography, Row, Col, Spin } from 'antd';
import ProductPreview from 'components/molecules/ProductPreview';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchOrderById } from 'redux/orders/thunks';
import localizeNumber from 'utils/localizeNumber';
import './index.scss';
import BorderedSection from 'components/organsims/BorderedSection';

const SingleOrder = () => {
  const { t, i18n } = useTranslation('screens');
  const { t: globalTranslation } = useTranslation('global');
  const locale = i18n.language;

  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const order = useSelector((state) => Orders.selectors.selectById(state, id));
  const orderProducts = useSelector(Orders.selectors.selectOrderProducts)(id);
  const address = useSelector((state) => Addresses.selectors.selectById(state, order?.address));
  const user = useSelector((store) => store.auth.currentUser);

  useEffect(() => {
    setLoading(false);
    dispatch(fetchOrderById({ id })).then(() => setLoading(true));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchById({ id: order?.address }));
  }, [dispatch, order?.address]);

  const renderItem = (item) => <ProductPreview productOrder={item} />;

  const getTranslatedPaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return t('payment_success.payment_method.cash');
      case 'online':
        return t('payment_success.payment_method.online');
      case 'visa_on_delivery':
        return t('payment_success.payment_method.visa_on_delivery');
      case 'visa_on_delivery_installments':
        return t('payment_success.payment_method.visa_on_delivery_installments');
      default:
        return t('payment_success.payment_method.unknown');
    }
  };
  const getTranslatedStatus = (status) => {
    switch (status) {
      case 'pending':
        return t('my_account.orders.table.status_options.pending');
      case 'confirmed':
        return t('my_account.orders.table.status_options.confirmed');
      case 'processing':
        return t('my_account.orders.table.status_options.processing');
      case 'delivering':
        return t('my_account.orders.table.status_options.delivering');
      case 'canceled':
        return t('my_account.orders.table.status_options.canceled');
      case 'delivered':
        return t('my_account.orders.table.status_options.delivered');
      case 'refunded':
        return t('my_account.orders.table.status_options.refunded');
      case 'partially_refunded':
        return t('my_account.orders.table.status_options.partially_refunded');
      default:
        return t('my_account.orders.table.status_options.unknown');
    }
  };
  return (
    <div className="single-order-wrapper">
      {loading ? (
        <div className="single-order">
          <div className="number-date-wrapper">
            <Typography className="order-number">
              {t('my_account.orders.single_order.order_title', {
                order_number: id,
              })}
            </Typography>
            <Typography className="order-date">
              {t('my_account.orders.single_order.placed_on')}{' '}
              {order?.checkout_date &&
                new Intl.DateTimeFormat(locale === 'ar' ? 'ar-EG' : 'en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }).format(new Date(order?.checkout_date))}
            </Typography>
          </div>
          <Row className="order-wrapper" gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6} className="datails-wrapper">
              <BorderedSection className="section-radius">
                <div className="order-details">
                  <Typography.Text strong>{t('my_account.orders.single_order.order_details')}</Typography.Text>
                  <Row>
                    <Col span={12}>
                      <Typography.Text>{t('my_account.orders.single_order.status')}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text>{getTranslatedStatus(order?.status)}</Typography.Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography.Text>{t('my_account.orders.single_order.shipping')}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text>
                        {localizeNumber(order?.checkout_shipping_fees)} {globalTranslation('currency')}
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography.Text>{t('my_account.orders.single_order.total')}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text>
                        {localizeNumber(order?.checkout_total_price)} {globalTranslation('currency')}
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Typography.Text>{t('my_account.orders.single_order.payment_method')}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text>{getTranslatedPaymentMethod(order?.payment_method)}</Typography.Text>
                    </Col>
                  </Row>
                </div>
              </BorderedSection>
              <BorderedSection className="section-radius">
                <div className="delivery-address">
                  <Typography.Text strong>{t('my_account.orders.single_order.delivery_address')}</Typography.Text>
                  {address && (
                    <Row>
                      <Col xs={24} sm={24} m={24} lg={24} xl={24}>
                        <Typography.Text className="address-info">{user?.full_name} </Typography.Text>
                      </Col>
                      <Col xs={24} sm={24} m={24} lg={24} xl={24}>
                        <Typography.Text className="address-info">{address?.phone_number} </Typography.Text>
                      </Col>
                      <Col xs={24} sm={24} m={24} lg={24} xl={24}>
                        <Typography.Text className="address-info detailed-address">
                          {address?.building} {address?.street_name}, {address?.district} {address?.governorate}
                        </Typography.Text>
                      </Col>
                    </Row>
                  )}
                </div>
              </BorderedSection>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={18} xxl={18} className="products-wrapper">
              <BorderedSection className="section-radius">
                <Space direction="vertical" className="orders-list-wrapper" fullWidth>
                  <List dataSource={orderProducts} renderItem={renderItem} split={false} />
                </Space>
              </BorderedSection>
            </Col>
          </Row>
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default SingleOrder;
