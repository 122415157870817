import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { CategorySchema } from 'redux/schema';
import CategoriesAPI from './services';

export const fetchCategories = createAsyncThunk('categories/fetchAll', async (filters = {}, { rejectWithValue }) => {
  try {
    const response = await CategoriesAPI.fetchAll({ params: { show_all: true, ...filters } });

    const normalized = normalize(response.data.categories, CategorySchema.list);
    return normalized.entities;
  } catch (error) {
    return rejectWithValue(error.data);
  }
});
