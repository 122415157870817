import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from 'router';
import Brands from './brands';
import Products from './products';
import paginationSlice from './_pagination';
import loadingSlice from './_loading';
import Categories from './categories';
import Users from './users';
import Orders from './orders';
import Addresses from './addresses';
import PageSettings from './pageSettings';

import Cart from './cart';
import { authSlice } from './auth';
import Payments from './payments';
import Branches from './branches';
import Promotions from './promotions';
import Installments from './installments';
import Banks from './banks';

const rootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    _pagination: paginationSlice.reducer,
    _loading: loadingSlice.reducer,
    brands: Brands.slice.reducer,
    products: Products.slice.reducer,
    categories: Categories.slice.reducer,
    users: Users.slice.reducer,
    orders: Orders.slice.reducer,
    addresses: Addresses.slice.reducer,
    cart: Cart.slice.reducer,
    payments: Payments.slice.reducer,
    auth: authSlice.reducer,
    branches: Branches.slice.reducer,
    pageSettings: PageSettings.slice.reducer,
    promotions: Promotions.slice.reducer,
    installments: Installments.slice.reducer,
    banks: Banks.slice.reducer,
  });

export default rootReducer;
