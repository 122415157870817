import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userSignin } from 'redux/auth/thunks';
import Orders from 'redux/orders';
import adapter from './adapter';
import { addAddress, editAddress, deleteAddress, fetchAddresses, fetchById } from './thunks';

export const slice = createSlice({
  name: 'addresses',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddresses.fulfilled, (state, action) => {})
      .addCase(userSignin.fulfilled, (state, action) => {
        if (!action.payload.data.addresses) {
          adapter.removeAll(state);
          return;
        }
        adapter.setAll(state, action.payload.data.addresses);
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        if (!action.payload) return;
        adapter.removeOne(state, action.payload);
      })
      .addMatcher(
        isAnyOf(
          Orders.thunks.fetchOrders.fulfilled,
          fetchAddresses.fulfilled,
          fetchById.fulfilled,
          addAddress.fulfilled,
          editAddress.fulfilled,
        ),
        (state, action) => {
          if (!action.payload.addresses) return;
          adapter.upsertMany(state, action.payload.addresses);
        },
      );
  },
});
const selectors = {
  ...adapter.getSelectors((state) => state['addresses']),
};

const Addresses = {
  adapter,
  slice,
  selectors,
};
export default Addresses;
