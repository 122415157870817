import i18n from 'translation/i18n';

const localizeNumber = (number, disableGrouping = false) => {
  const lang = i18n.language === 'ar' ? 'ar-EG' : i18n.language;
  const localeOptions = disableGrouping
    ? {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: false,
      }
    : {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      };
  return number?.toLocaleString(lang, localeOptions);
};

export default localizeNumber;
