import axios from 'axios';

const fetchAll = ({ params }) => axios.get('v1/users/orders', { params });
const fetchById = ({ id }) => axios.get(`v1/users/orders/${id}`);
const submitOrderAddress = ({ order_id, address_id }) =>
  axios.put(`v1/users/orders/${order_id}/address`, { address_id });

const ratePrevOrders = ({ ratings }) => axios.put('/v1/users/orders/rate', { ratings });

const OrdersAPI = {
  fetchAll,
  fetchById,
  submitOrderAddress,
  ratePrevOrders,
};

export default OrdersAPI;
