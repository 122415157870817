import { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Col, Row } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { useTranslation } from 'react-i18next';
import entries from 'lodash/entries';
import { useTimer } from 'use-timer';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import useMountedState from 'react-use/lib/useMountedState';
import { userConfirmation, userForgotPassword, userResendOTP } from 'redux/auth/thunks';
import BorderedSection from 'components/organsims/BorderedSection';
import { userResetPassword } from 'redux/auth/thunks';
import Logo from 'assets/images/Abdelaziz-stores-logo.png';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xxl: { span: 24 },
  },
};

const OTP = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const { t } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');

  const { state: { phone_number, previous: previousRoute } = {} } = location;
  if (!phone_number || !previousRoute) history.replace('/404');

  const [loading, setLoading] = useState(false);
  const [lockResend, setLockResend] = useState(false);

  const { time, start, reset, advanceTime } = useTimer({
    initialTime: 120,
    endTime: 0,
    timerType: 'DECREMENTAL',
  });

  const onResendNotConfirmed = useCallback(
    () =>
      dispatch(userResendOTP({ phone_number }))
        .then(unwrapResult)
        .then(() => {
          reset();
          start();
        })
        .catch((error) => {
          Modal.error({
            title: screenTranslation('otp.error'),
            content: (
              <>
                {error?.data?.error?.map((errorItem, index) => (
                  <Typography.Text key={index}>{errorItem}</Typography.Text>
                ))}
              </>
            ),
          });
        })
        .finally(() => setLockResend(false)),
    [dispatch, phone_number, reset, start, t],
  );

  const onResendConfirmed = useCallback(
    () =>
      dispatch(userForgotPassword({ phone_number }))
        .then(unwrapResult)
        .then(() => {
          reset();
          start();
        })
        .catch((error) => {
          if (error?.status === 404) {
            form.setFields([
              {
                name: 'phone_number',
                errors: [screenTranslation('forgot_password.feedback.errors.404')],
              },
            ]);
          } else {
            Modal.error({
              title: screenTranslation('forgot_password.feedback.errors.error'),
              content: (
                <>
                  {error?.data?.error?.map((error) => (
                    <Typography.Text key={error}>{error}</Typography.Text>
                  ))}
                </>
              ),
            });
          }
        })
        .finally(() => setLockResend(false)),
    [dispatch, form, phone_number, reset, start, t],
  );

  const onResendOTP = useCallback(() => {
    if (time === 0) {
      setLockResend(true);
      switch (previousRoute) {
        case 'forgot_password':
          onResendConfirmed();
          break;
        default:
          onResendNotConfirmed();
      }
    } else {
      Modal.warning({ content: screenTranslation('otp.timer_error', { time }) });
    }
  }, [previousRoute, onResendConfirmed, onResendNotConfirmed, t, time]);

  const onConfirmUser = (data) =>
    dispatch(userConfirmation(data))
      .then(unwrapResult)
      .then(() => {
        Modal.success({
          title: screenTranslation('otp.feedback.success.title'),
          content: screenTranslation('otp.feedback.success.content'),
          okText: screenTranslation('otp.feedback.success.ok'),
          onOk: () => history.push('/auth/signin'),
        });
      })
      .catch((error) => {
        if (error?.status === 404)
          form.setFields([
            {
              name: 'sms_confirmation_otp',
              errors: [screenTranslation('otp.feedback.errors.404')],
            },
          ]);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });

  const onFinish = (data) => {
    setLoading(true);
    onConfirmUser(data);
  };

  const resetPassword = (data) => {
    dispatch(userResetPassword({ ...data }))
      .then(unwrapResult)
      .then(() => {
        history.push('/auth/signin');
      })
      .catch((error) => {
        if (error?.status === 404) {
          form.setFields([
            {
              name: 'sms_password_reset_otp',
              errors: [screenTranslation('otp.feedback.errors.404')],
            },
          ]);
        } else if (error.data?.error) {
          Modal.error({
            title: screenTranslation('forgot_password.feedback.errors.error'),
            content: (
              <>
                {error?.data?.error?.map((error) => (
                  <Typography.Text key={error}>{error}</Typography.Text>
                ))}
              </>
            ),
          });
        } else if (error?.data) {
          entries(error.data)?.forEach(([name, errors]) => {
            form.setFields([
              {
                name,
                errors,
              },
            ]);
          });
        }
      });
  };
  useEffect(() => {
    if (previousRoute === 'signin') advanceTime(120);
    else start();
  }, [start, advanceTime, previousRoute]);

  return (
    <div className="auth-wrapper">
      <Row className="header-wrapper">
        <a href="/">
          <img className="logo" src={Logo} alt="Abdul Aziz Logo" />
        </a>
      </Row>
      <Row className="tabs-wrapper" gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={14} xxl={14}>
          <div className="banner-wrapper">
            <>
              <span>{screenTranslation('otp.title')}</span>
              <span className="subtitle">{screenTranslation('otp.description')}</span>
            </>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xxl={10}>
          <BorderedSection className="section-radius">
            <Form
              form={form}
              name="otp"
              onFinish={previousRoute === 'forgot_password' ? resetPassword : onFinish}
              layout="vertical"
              {...formItemLayout}
            >
              <Form.Item
                name={previousRoute !== 'forgot_password' ? 'sms_confirmation_otp' : 'sms_password_reset_otp'}
                label={screenTranslation('otp.form.otp_code')}
                rules={[
                  {
                    required: true,
                    message: screenTranslation('otp.form.otp_code_validation'),
                  },
                ]}
              >
                <Input placeholder={screenTranslation('otp.form.otp_code_placeholder')} />
              </Form.Item>
              {previousRoute !== 'forgot_password' && (
                <Space>
                  <Button type="text" disabled={time !== 0 || lockResend} onClick={onResendOTP}>
                    <Typography.Text type="secondary">{screenTranslation('otp.form.send_again')}</Typography.Text>
                  </Button>
                  <Space>
                    <ClockCircleOutlined />
                    <Typography.Text>{time}</Typography.Text>
                  </Space>
                </Space>
              )}
              {previousRoute !== 'forgot_password' && (
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {screenTranslation('otp.form.verify')}
                  </Button>
                </Form.Item>
              )}
              {previousRoute === 'forgot_password' && (
                <>
                  <Form.Item
                    name="password"
                    label={screenTranslation('change-password.newPassword')}
                    rules={[
                      {
                        required: true,
                        message: screenTranslation('change-password.new_password_validation'),
                      },
                    ]}
                  >
                    <Input.Password placeholder={screenTranslation('change-password.new_password_placeholder')} />
                  </Form.Item>
                  <Form.Item
                    name="password_confirmation"
                    label={screenTranslation('change-password.confirmPassword')}
                    rules={[
                      {
                        required: true,
                        message: screenTranslation('change-password.confirm_password_validation'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(screenTranslation('change-password.password_confirmation_matching'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder={screenTranslation('change-password.password_confirmation_placeholder')}
                    />
                  </Form.Item>
                  <Space>
                    <Button type="text" disabled={time !== 0 || lockResend} onClick={onResendOTP}>
                      <Typography.Text type="secondary">{screenTranslation('otp.form.send_otp_again')}</Typography.Text>
                    </Button>
                    <Space>
                      <ClockCircleOutlined />
                      <Typography.Text>{time}</Typography.Text>
                    </Space>
                  </Space>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t('reset_password')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form>
          </BorderedSection>
        </Col>
      </Row>
      <span className="copyright">{t('copyright')}</span>
    </div>
  );
};

export default OTP;
