import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import selectors from './selectors';

export const slice = createSlice({
  name: 'orders',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchOrders.fulfilled, (state, action) => {
        if (!action.payload.entities.orders) return;
        adapter.setAll(state, action.payload.entities.orders);
      })
      .addCase(thunks.fetchOrderById.fulfilled, (state, action) => {
        if (!action.payload.entities.orders) return;
        adapter.upsertMany(state, action.payload.entities.orders);
      });
  },
});
const Orders = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Orders;
