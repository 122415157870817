import axios from 'axios';

const fetchAll = ({ params }) => axios.get('v1/users/addresses', { params });
const fetchById = ({ id }) => axios.get(`v1/users/addresses/${id}`);

const addAddress = (data) => axios.post('v1/users/addresses', data);
const editAddress = ({ id, address }) => axios.put(`v1/users/addresses/${id}`, address);
const deleteAddress = ({ id }) => axios.delete(`v1/users/addresses/${id}`);

const AddressesAPI = {
  fetchAll,
  fetchById,
  addAddress,
  editAddress,
  deleteAddress,
};

export default AddressesAPI;
