import { createAsyncThunk } from '@reduxjs/toolkit';
import UsersAPI from './services';
import { normalize } from 'normalizr';
import { UsersSchema } from 'redux/schema';

export const changePassword = createAsyncThunk('users/changePassword', async (data, thunkAPI) => {
  try {
    const response = await UsersAPI.changePassword(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
export const editProfile = createAsyncThunk('users/editProfile', async (data, thunkAPI) => {
  try {
    const response = await UsersAPI.editProfile(data);
    const normalized = normalize(response.data.user, UsersSchema.entity);
    return { data: normalized.entities, headers: response.headers };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
