import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { AddressesSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import AddressesAPI from './services';

export const fetchAddresses = createPaginatedAsyncThunk('addresses/fetchAll', async ({ params }, { extra }) => {
  const response = await AddressesAPI.fetchAll({ params });
  const normalized = normalize(response.data.addresses, AddressesSchema.list);
  return extra.withPaginationMeta(response, normalized.entities);
});

export const fetchById = createAsyncThunk('addresses/fetchById', async ({ id }, thunkAPI) => {
  try {
    const response = await AddressesAPI.fetchById({ id });
    const normalized = normalize(response.data.address, AddressesSchema.entity);
    return normalized.entities;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const addAddress = createAsyncThunk('addresses/add', async (data, thunkAPI) => {
  try {
    const response = await AddressesAPI.addAddress(data);
    const normalized = normalize(response.data.address, AddressesSchema.entity);
    return normalized.entities;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const editAddress = createAsyncThunk('addresses/edit', async (data, thunkAPI) => {
  try {
    const response = await AddressesAPI.editAddress(data);
    const normalized = normalize(response.data.address, AddressesSchema.entity);
    return normalized.entities;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const deleteAddress = createAsyncThunk('addresses/edit', async ({ id }, thunkAPI) => {
  try {
    await AddressesAPI.deleteAddress({ id });
    return id;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
