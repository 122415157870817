import { useState } from 'react';
import { Button, Form, Input, Row, Col, Modal, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { editProfile } from 'redux/users/thunks';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import entries from 'lodash/entries';
import './index.scss';

const colSpacing = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 14,
  xxl: 16,
};
const EditProfile = ({ onBack }) => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const currentUser = useSelector((store) => store.auth.currentUser);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = (user) => {
    setLoading(true);
    dispatch(editProfile({ user }))
      .then(unwrapResult)
      .then(() =>
        Modal.success({
          title: t('edit-profile.success.title'),
          content: t('edit-profile.success.content'),
          okText: t('edit-profile.success.ok'),
          onOk: () => {
            history.push('/my-account/account');
          },
        }),
      )
      .catch((errors) => {
        entries(errors)?.forEach(([name, errors]) => {
          form.setFields([
            {
              name,
              errors,
            },
          ]);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="edit-profile-wrapper">
        <ArrowLeftOutlined onClick={onBack} />
        <Typography className="edit-profile-title">{t('edit-profile.title')}</Typography>
      </div>
      <Form form={form} initialValues={currentUser} onFinish={onSubmit} className="edit-profile-form">
        <Row gutter={50}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('signup.form.first_name_validation') }]}
              colon={false}
              name="first_name"
              label={t('signup.form.first_name')}
            >
              <Input placeholder={t('signup.form.first_name_placeholder')} />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('signup.form.last_name_validation') }]}
              colon={false}
              name="last_name"
              label={t('signup.form.last_name')}
            >
              <Input placeholder={t('signup.form.last_name_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('signup.form.email_validation') }]}
              colon={false}
              name="email"
              label={t('signup.form.email')}
            >
              <Input placeholder={t('signup.form.email_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={4} xl={4} xxl={6}>
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                {t('edit-profile.edit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default EditProfile;
