import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { PromotionsSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import PromotionsAPI from './services';

export const fetchPromotions = createPaginatedAsyncThunk('promotions/fetchAll', async ({ params }, { extra }) => {
  const response = await PromotionsAPI.fetchAll({ params });
  const normalized = normalize(response.data.promotions, PromotionsSchema.list);
  return extra.withPaginationMeta(response, normalized);
});

export const fetchPromotion = createAsyncThunk('promotions/fetch/id', async ({ id }) => {
  const response = await PromotionsAPI.fetchById({ id });
  const normalized = normalize(response.data.promotion, PromotionsAPI.entity);
  return normalized.entities;
});
