import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import selectors from './selectors';

export const slice = createSlice({
  name: 'promotions',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchPromotions.fulfilled, (state, action) => {
        if (!action.payload.entities.promotions) return;
        adapter.upsertMany(state, action.payload.entities.promotions);
      })
      .addCase(thunks.fetchPromotion.fulfilled, (state, action) => {
        if (!action.payload.promotions) return;
        adapter.upsertMany(state, action.payload.promotions);
      });
  },
});

const Promotions = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Promotions;
