import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Modal, Typography } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from 'redux/cart/thunks';
import ItemCounter from 'components/atoms/ItemCounter';
import Products from 'redux/products';

import './AddToCart.scss';

const AddToCart = ({ id, isAvailable, showCounter = false }) => {
  const dispatch = useDispatch();
  const cart = useSelector((store) => store.cart);
  const product = useSelector((state) => Products.selectors.selectById(state, id));
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('atoms');
  const [count, setCount] = useState(1);

  const stockMaxValue = product?.stock || 1;
  const orderMaxValue = product?.maximum_number_per_order || stockMaxValue;

  useEffect(() => {
    setIsAlreadyAdded(cart.ids.includes(id));
  }, [cart, id]);

  const handleError = (error) => {
    Modal.error({
      content: (
        <>
          {error?.errors?.map((errorItem) => (
            <Typography.Text>{errorItem}</Typography.Text>
          ))}
        </>
      ),
    });
  };

  const onPress = () => {
    if (isAlreadyAdded) {
      setLoading(true);
      dispatch(removeFromCart({ order_product: { product_id: id, _destroy: 'true' } }))
        .then(unwrapResult)
        .catch(handleError)
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      dispatch(addToCart({ order_product: { product_id: id, quantity: count, _destroy: 'false' } }))
        .then(unwrapResult)
        .catch(handleError)
        .finally(() => setLoading(false));
    }
  };

  const onCountChange = useCallback((newCount) => {
    setCount(newCount);
  }, []);
  return (
    <div>
      {showCounter && product?.stock !== 0 && (
        <div className="quantity-wrapper">
          <span className="quantity-text">{t('quantity')} </span>
          <ItemCounter
            defaultValue={1}
            onValueChange={onCountChange}
            stockMaxValue={stockMaxValue}
            orderMaxValue={orderMaxValue}
            onRemoveProduct={() => {}}
          />
        </div>
      )}

      <Button
        className={`add-to-cart ${isAlreadyAdded ? 'remove' : 'add'}`}
        onClick={onPress}
        loading={loading}
        disabled={!isAvailable}
      >
        {!isAlreadyAdded ? t('add_to_cart.add') : t('add_to_cart.remove')}
      </Button>
    </div>
  );
};

export default AddToCart;
