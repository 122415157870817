import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import selectors from './selectors';

export const slice = createSlice({
  name: 'pageSettings',
  initialState: { page_settings: {} },
  extraReducers: (builder) => {
    builder.addCase(thunks.fetchPageSettings.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.page_settings = { ...state.page_settings, ...payload };
    });
  },
});

const PageSettings = {
  slice,
  thunks,
  selectors,
};

export default PageSettings;
