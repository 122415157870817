import indexOf from 'lodash/indexOf';

export const getAddressComponents = (address) => {
  return address.reduce((accumulator, current) => {
    if (indexOf(current?.types, 'street_number') !== -1) return { ...accumulator, building: current?.long_name };
    if (indexOf(current?.types, 'route') !== -1) return { ...accumulator, street_name: current?.long_name };
    if (indexOf(current?.types, 'administrative_area_level_2') !== -1)
      return { ...accumulator, district: current?.long_name };
    if (indexOf(current?.types, 'administrative_area_level_1') !== -1)
      return { ...accumulator, governorate: current?.long_name };
    return accumulator;
  }, {});
};

export default getAddressComponents;
