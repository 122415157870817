import axios from 'axios';

const signin = (data) => axios.post('v1/users/sign_in', data);
const signup = (data) => axios.post('v1/users', data);
const confirmUser = (data) => axios.put('v1/users/confirmations', data);
const resendOtp = (data) => axios.post('v1/users/otps', data);
const forgotPassword = (data) => axios.post('v1/users/passwords', data);
const resetPassword = (data) => axios.put('v1/users/passwords', data);

const logout = () => axios.delete('v1/users/sign_out');

const AuthenticationAPI = {
  signin,
  signup,
  confirmUser,
  resendOtp,
  forgotPassword,
  resetPassword,
  logout,
};

export default AuthenticationAPI;
