import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import values from 'lodash/values';
import adapter from './adapter';
import isEmpty from 'lodash/isEmpty';

const byId = createSelector(adapter.getSelectors((store) => store['brands']).selectEntities, (items) =>
  memoize((id) =>
    values(items)
      .filter((item) => item.id === id)
      .map((item) => item),
  ),
);

const getFeaturedBrands = createSelector(adapter.getSelectors((store) => store['brands']).selectEntities, (items) =>
  values(items).filter((item) => item.featured),
);

const helperBrandSearch = (brand, brandId) => {
  if (brand.id === brandId) return brand;
  else if (!isEmpty(brand.children)) {
    for (let i = 0; i < brand.children.length; i++) {
      let result = helperBrandSearch(brand.children[i], brandId);
      if (!isEmpty(result)) return result;
    }
  }
  return {};
};
const brandSearch = createSelector(adapter.getSelectors((store) => store['brands']).selectEntities, (items) =>
  memoize((brandId) => {
    let result = {};
    for (let i = 0; i < values(items).length; i++) {
      result = helperBrandSearch(values(items)[i], brandId);
      if (!isEmpty(result)) return result;
    }
    return result;
  }),
);

const selectors = {
  byId,
  getFeaturedBrands,
  brandSearch,
  ...adapter.getSelectors((state) => state['brands']),
};
export default selectors;
