import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import adapter from './adapter';

const mainCategories = createSelector(adapter.getSelectors((store) => store['categories']).selectEntities, (items) =>
  values(items)
    .filter((item) => !item.parent)
    .map((item) => item.id),
);

const childrenCategories = createSelector(
  adapter.getSelectors((store) => store['categories']).selectEntities,
  (items) =>
    values(items)
      .map((item) =>
        item.children
          .map((parent) => {
            if (parent.children.length > 0) {
              return parent.children;
            }
            return parent;
          })
          .flat(),
      )
      .flat(),
);
const allChildrenCategories = createSelector(
  adapter.getSelectors((store) => store['categories']).selectEntities,
  (items) =>
    values(items)
      .map((item) =>
        item.children
          .map((parent) => {
            if (parent.children.length > 0) {
              return [parent, ...parent.children];
            }
            return parent;
          })
          .flat(),
      )
      .flat(),
);
const helperCategorySearch = (category, categoryId) => {
  if (category.id === categoryId) return category;
  else if (!isEmpty(category.children)) {
    for (let i = 0; i < category.children.length; i++) {
      let result = helperCategorySearch(category.children[i], categoryId);
      if (!isEmpty(result)) return result;
    }
  }
  return {};
};

const categorySearch = createSelector(adapter.getSelectors((store) => store['categories']).selectEntities, (items) =>
  memoize((categoryId) => {
    let result = {};
    for (let i = 0; i < values(items).length; i++) {
      result = helperCategorySearch(values(items)[i], categoryId);
      if (!isEmpty(result)) return result;
    }
    return result;
  }),
);

const byId = createSelector(adapter.getSelectors((store) => store['categories']).selectEntities, (items) =>
  memoize((categoryId) => values(items).find((item) => item.id === categoryId)),
);

const selectors = {
  mainCategories,
  categorySearch,
  byId,
  childrenCategories,
  allChildrenCategories,
  ...adapter.getSelectors((state) => state['categories']),
};

export default selectors;
