import { Carousel, List, Spin, Row, Col, Tabs, Typography, Button } from 'antd';
import ProductsList from 'components/organsims/ProductsList';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { fetchProduct, fetchProducts } from 'redux/products/thunks';
import { selectLatestProducts } from 'redux/products/selectors';
import { fetchPageSettings } from 'redux/pageSettings/thunks';
import { pageSettingsSelector } from 'redux/pageSettings/selectors';
import chunk from 'lodash/chunk';
import { recentyViewedSelector } from 'redux/auth/selectors.js';
import { fetchCategories } from 'redux/categories/thunks';
import { fetchAllBrands } from 'redux/brands/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import ProductCard from 'components/molecules/ProductCard';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const HomePage = () => {
  const carouselRef = useRef();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('screens');
  const history = useHistory();

  const smallMobileView = useMedia('(max-width: 375px');
  const mobileView = useMedia('(max-width: 576px');
  const mediumView = useMedia('(max-width: 768px)');
  const ipadView = useMedia('(max-width: 1024px)');
  const labtopView = useMedia('(max-width: 1268px)');
  const extraLargeView = useMedia('(min-width: 1650px)');

  const latestProducts = useSelector(selectLatestProducts)(mediumView ? 4 : extraLargeView ? 10 : 8);
  const recentlyViewed = useSelector(recentyViewedSelector);
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const [promotionsProducts, setPromotionsProducts] = useState([]);
  const [loadingPromotions, setLoadingPromotions] = useState(false);

  const {
    top_slider_first,
    top_slider_second,
    top_slider_first_ar,
    top_slider_second_ar,
    top_slider_first_link,
    top_slider_second_link,
    explore_first_image,
    explore_second_image,
    explore_third_image,
    explore_fourth_image,
    explore_first_image_ar,
    explore_second_image_ar,
    explore_third_image_ar,
    explore_fourth_image_ar,
    explore_first_link,
    explore_second_link,
    explore_third_link,
    explore_fourth_link,
    video_banner_image,
    video_banner_link,
  } = useSelector(pageSettingsSelector)('home_page') || {};

  const [pageLoading, setPageLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [brands, setBrands] = useState([]);

  const [activeTab, setActiveTab] = useState('featured');
  const [categories, setCategories] = useState([]);

  const renderBrand = (brand) => (
    <Row key={brand.id} className="brand-item">
      <Col className="brand-content">
        <img src={brand.icon_url} alt={brand.name} />
      </Col>
    </Row>
  );

  const renderOffersProducts = useCallback(() => {
    const dividedProducts = chunk(
      promotionsProducts,
      mobileView ? 1 : mediumView ? 2 : labtopView ? 3 : extraLargeView ? 5 : 4,
    );
    return dividedProducts.map((singleGroup) => (
      <List
        key={singleGroup[0]}
        grid={{
          gutter: 8,
          column: mobileView ? 1 : mediumView ? 2 : labtopView ? 3 : extraLargeView ? 5 : 4,
        }}
        dataSource={singleGroup}
        className="slider-children-wrapper"
        renderItem={(product) => (
          <List.Item>
            <ProductCard productId={product} />
          </List.Item>
        )}
      />
    ));
  }, [promotionsProducts, ipadView, mobileView]);

  const totalSlides = renderOffersProducts().length;

  const isAbsoluteURL = (url) => {
    return /^https?:\/\//.test(url);
  };

  const handleClick = (link) => {
    if (isAbsoluteURL(link)) {
      window.location.href = link;
    } else {
      history.push(link);
    }
  };

  const renderLink = (link, image) => {
    const linkExists = link;
    return (
      <div className="shop-now-wrapper">
        <img src={image?.attachment} alt="carousel" />
        {linkExists && (
          <Button onClick={() => handleClick(link)} className="shop-now-button">
            {t('homepage.shop_now')}
          </Button>
        )}
      </div>
    );
  };
  const promotionsSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: currentSlide === totalSlides - 1 ? '0%' : smallMobileView ? '1%' : '3%',
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    afterChange: (current) => setCurrentSlide(current),
  };

  useEffect(() => {
    dispatch(fetchCategories({ show_on_homepage: true }))
      .then(unwrapResult)
      .then((res) => {
        const categoriesObject = res?.categories;
        const categoriesArray = categoriesObject
          ? Array.isArray(categoriesObject)
            ? categoriesObject
            : Object.values(categoriesObject)
          : [];
        setCategories(categoriesArray.slice(0, 10));
      });
    dispatch(fetchAllBrands({ params: { show_on_homepage: true } }))
      .then(unwrapResult)
      .then((res) => {
        const brandsObject = res.entities?.brands;
        const brandsArray = brandsObject
          ? Array.isArray(brandsObject)
            ? brandsObject
            : Object.values(brandsObject)
          : [];
        setBrands(brandsArray);
      });
  }, [dispatch, i18n.language]);

  useEffect(() => {
    dispatch(
      fetchProducts({ params: { featured: true, per_page: mediumView ? 4 : extraLargeView ? 10 : 8 }, refresh: true }),
    )
      .then(unwrapResult)
      .then((response) => {
        setFeaturedProducts(response.result);
      })
      .catch((error) => {
        console.error('Fetch products error:', error);
      });
  }, [dispatch]);

  const pageSettings = useSelector(pageSettingsSelector)('home_page');

  useEffect(() => {
    if (!pageSettings) {
      setPageLoading(true);
      dispatch(fetchPageSettings({ params: { group: 'home_page' } })).then(() => setPageLoading(false));
    } else {
      setPageLoading(false);
    }
  }, [dispatch, pageSettings]);

  const carouselContent = useMemo(
    () => (
      <Carousel className="homepage-carousel" autoplay>
        <div className="shop-now-wrapper">
          <img
            loading="lazy"
            src={i18n.language === 'ar' ? top_slider_first_ar?.attachment : top_slider_first?.attachment}
            alt="carousel"
          />
        </div>
        <div className="shop-now-wrapper">
          <img
            src={i18n.language === 'ar' ? top_slider_second_ar?.attachment : top_slider_second?.attachment}
            alt="carousel"
          />
        </div>
      </Carousel>
    ),
    [i18n.language, top_slider_first_ar, top_slider_first, top_slider_second_ar, top_slider_second],
  );

  useEffect(() => {
    recentlyViewed?.map((id) => dispatch(fetchProduct({ id })));
  }, [dispatch, recentlyViewed]);

  useEffect(() => {
    setLoadingPromotions(true);
    dispatch(
      fetchProducts({
        params: { in_active_promotion: true, per_page: mediumView ? 4 : extraLargeView ? 10 : 8 },
        refresh: true,
      }),
    )
      .then(unwrapResult)
      .then((response) => {
        setPromotionsProducts(response.result);
        setLoadingPromotions(false);
        dispatch(fetchProducts({ params: { latest_to_oldest: true }, refresh: true }));
      });
  }, [dispatch]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'new_arrivals':
        return <ProductsList products={latestProducts} columns={4} />;
      case 'resently_viewed':
        return <ProductsList products={recentlyViewed} columns={4} />;
      case 'featured':
        return <ProductsList products={featuredProducts} columns={4} />;
      default:
        return null;
    }
  };

  return (
    <div className="homepage">
      {pageLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <div className="homepage-carousel-wrapper">{carouselContent}</div>
      )}
      <div className="homepage-screen-wrapper">
        <Row className="explore-section">
          <Col xxs={24} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {renderLink(
                  explore_first_link?.value,
                  i18n.language === 'ar' ? explore_first_image_ar : explore_first_image,
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {renderLink(
                  explore_second_link?.value,
                  i18n.language === 'ar' ? explore_second_image_ar : explore_second_image,
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                {renderLink(
                  explore_third_link?.value,
                  i18n.language === 'ar' ? explore_third_image_ar : explore_third_image,
                )}
              </Col>
            </Row>
          </Col>
          <Col xxs={24} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} className="explore-fourth-image">
            {renderLink(
              explore_fourth_link?.value,
              i18n.language === 'ar' ? explore_fourth_image_ar : explore_fourth_image,
            )}
          </Col>
        </Row>
        <div className="category-carousel-wrapper">
          <Typography className="shop-title">{t('homepage.shop-by-categories')}</Typography>
          <List
            grid={{
              gutter: 16,
              xs: smallMobileView ? 2 : 4,
              sm: 5,
              md: 5,
              lg: 10,
              xl: 10,
            }}
            dataSource={categories}
            renderItem={(category) => (
              <List.Item key={category.id}>
                <Link className="category-content" to={`/products?category_id=${category.id}`}>
                  <img src={category.icon_url} alt={category.name} />
                  <p>{category.name}</p>
                </Link>
              </List.Item>
            )}
          />
        </div>

        <div className="homepage-screen">
          <div className="tabs-section">
            <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
              <Tabs.TabPane tab={t('homepage.featured.title')} key="featured" />
              <Tabs.TabPane tab={t('homepage.new-arrivals.title')} key="new_arrivals" />
              <Tabs.TabPane tab={t('homepage.recently-viewed.title')} key="resently_viewed" />
            </Tabs>
            <div className="tab-content">{renderActiveTabContent()}</div>
          </div>
          <div className="video-section">
            {video_banner_link?.value ? (
              <iframe
                width="100%"
                height="400"
                src={video_banner_link?.value}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              video_banner_image?.attachment && <img src={video_banner_image?.attachment} alt="Video Cover" />
            )}
          </div>
          {promotionsProducts.length !== 0 ? (
            <div className="promotions-section">
              <Typography className="offers-title">{t('homepage.offers.title')}</Typography>
              {!loadingPromotions ? (
                <Carousel ref={carouselRef} {...promotionsSettings}>
                  {renderOffersProducts()}
                </Carousel>
              ) : (
                <Spin />
              )}
            </div>
          ) : null}
          <div className="brands-section">
            <Row gutter={[16, 16]} justify="center">
              {brands.slice(0, 6).map((brand) => (
                <Col key={brand.id} xs={12} sm={8} md={6} lg={4} xl={3}>
                  {renderBrand(brand)}
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
