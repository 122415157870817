import { useLayoutEffect, useState } from 'react';
import PaymentFailure from './components/index.failure';
import PaymentSuccess from './components/index.success';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import './index.scss';

const Payment = () => {
  const { search } = useLocation();
  const params = queryString.parse(search.substring(1));

  const [success, setSuccess] = useState();

  useLayoutEffect(() => {
    setSuccess(params.success);
  }, [params.success]);

  return <div className="payment-screen">{success === 'true' ? <PaymentSuccess /> : <PaymentFailure />}</div>;
};

export default Payment;
