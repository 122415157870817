import axios from 'axios';

const fetchAll = ({ params }) => axios.get('v1/promotions', { params });
const fetchById = ({ id }) => axios.get(`v1/promotions/${id}`);

const PromotionsAPI = {
  fetchAll,
  fetchById,
};

export default PromotionsAPI;
