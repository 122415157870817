import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { BrandsSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import BrandsAPI from './services';

export const fetchBrand = createAsyncThunk('brands/fetch/id', async ({ id }) => {
  const response = await BrandsAPI.fetchById({ id });
  const normalized = normalize(response.data.brand, BrandsSchema.entity);
  return normalized.entities;
});

export const fetchBrands = createPaginatedAsyncThunk('brands/fetchAll', async ({ params }, { extra }) => {
  const response = await BrandsAPI.fetchAll({ params });
  const normalized = normalize(response.data.brands, BrandsSchema.list);
  return extra.withPaginationMeta(response, normalized);
});

export const fetchAllBrands = createAsyncThunk('brands/fetchAll', async ({ params }, { extra }) => {
  const response = await BrandsAPI.fetchAll({ params });
  const normalized = normalize(response.data.brands, BrandsSchema.list);
  return extra.withPaginationMeta(response, normalized);
});
