import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import values from 'lodash/values';
import adapter from './adapter';

const ordersSelector = adapter.getSelectors((store) => store['orders']);
const byId = createSelector(ordersSelector.selectEntities, (items) =>
  memoize((id) =>
    values(items)
      .filter((item) => item.id === id)
      .map((item) => item),
  ),
);

const selectOrderProducts = createSelector(ordersSelector.selectEntities, (items) =>
  memoize((id) => {
    const order = items[id];
    return order
      ? order.order_products.reduce((acc, value) => {
          const newValue = {
            ...value.product,
            price: value.checkout_price,
            checkout_price: value.checkout_price,
            quantity: value.quantity,
            order_status: order.status,
            refunded_quantity: value.refunded_quantity,
          };
          return [...acc, newValue];
        }, [])
      : [];
  }),
);

const selectors = {
  byId,
  selectOrderProducts,
  ...ordersSelector,
};
export default selectors;
