import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { OrdersSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import OrdersAPI from './services';

export const fetchOrders = createPaginatedAsyncThunk('orders/fetchAll', async ({ params }, { extra }) => {
  const response = await OrdersAPI.fetchAll({ params });
  const normalized = normalize(response.data.orders, OrdersSchema.list);
  return extra.withPaginationMeta(response, normalized);
});

export const fetchOrderById = createAsyncThunk('orders/fetchById', async ({ id }, { extra }) => {
  const response = await OrdersAPI.fetchById({ id });
  const normalized = normalize(response.data.order, OrdersSchema.entity);
  return normalized;
});

export const submitOrderAddress = createAsyncThunk('orders/address', async (data, thunkAPI) => {
  try {
    const response = await OrdersAPI.submitOrderAddress(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const rateOrders = createAsyncThunk('orders/ratings', async (data, thunkAPI) => {
  try {
    const response = await OrdersAPI.ratePrevOrders(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
