import { Tag } from 'antd';
import PropTypes from 'prop-types';
import './index.scss';

const CardLabels = ({ labels }) => (
  <div className="card-label-wrapper">
    {labels.map((label, index) => (
      <div key={index} className="card-label-container">
        <Tag>{label}</Tag>
      </div>
    ))}
  </div>
);

CardLabels.propTypes = {
  labels: PropTypes.array,
};

export default CardLabels;
