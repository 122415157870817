import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { UsersSchema } from 'redux/schema';
import AuthenticationAPI from './services';

export const userSignin = createAsyncThunk('user/signin', async (data, thunkAPI) => {
  try {
    const response = await AuthenticationAPI.signin(data);
    const normalized = normalize(response.data.user, UsersSchema.entity);
    return { data: normalized.entities, headers: response.headers };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const userSignup = createAsyncThunk('user/signup', async (data, thunkAPI) => {
  try {
    const response = await AuthenticationAPI.signup(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
export const userResendOTP = createAsyncThunk('user/resend_otp', async (data, thunkAPI) => {
  try {
    const response = await AuthenticationAPI.resendOtp(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const userForgotPassword = createAsyncThunk('user/forgot_password', async (data, thunkAPI) => {
  try {
    const response = await AuthenticationAPI.forgotPassword(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ data: error.data, status: error.status });
  }
});

export const userConfirmation = createAsyncThunk('user/confirm', async (data, thunkAPI) => {
  try {
    const response = await AuthenticationAPI.confirmUser(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ data: error.data, status: error.status });
  }
});

export const userResetPassword = createAsyncThunk('user/reset_password', async (data, thunkAPI) => {
  try {
    const response = await AuthenticationAPI.resetPassword(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ data: error.data, status: error.status });
  }
});
