import { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import { Tabs, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import AccountTab from './components/MyAccountTabs/Account';
import AddressesTab from '../../components/organsims/AddressesList';
import WishlistTab from './components/MyAccountTabs/Wishlist';
import OrdersTab from './components/MyAccountTabs/Orders';
import { useParams, useHistory } from 'react-router-dom';
import './index.scss';

const MyAccount = () => {
  const { t } = useTranslation('screens');
  const { tab: initialTab = 'account' } = useParams();
  const history = useHistory();
  const mobileView = useMedia('(max-width: 768px)');
  const [activeTab, setActiveTab] = useState(initialTab);
  const validTabs = ['account', 'addresses', 'wishlist', 'orders'];

  useEffect(() => {
    if (validTabs.includes(initialTab)) {
      setActiveTab(initialTab);
    } else {
      history.push('/my-account/account');
      setActiveTab('account');
    }
  }, [initialTab, history]);

  const handleTabChange = (key) => {
    setActiveTab(key);
    history.push(`/my-account/${key}`);
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'account':
        return <AccountTab />;
      case 'addresses':
        return <AddressesTab isDetailed />;
      case 'orders':
        return <OrdersTab />;
      case 'wishlist':
        return <WishlistTab />;
      default:
        return null;
    }
  };

  return (
    <div className="my-account-wrapper">
      <Typography className="my-account-title">{t('my_account.title')}</Typography>

      <Row>
        <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={4} className="tabs-section-wrapper">
          <div className="tabs-wrapper">
            <Tabs
              type="card"
              activeKey={activeTab}
              onChange={handleTabChange}
              tabPosition={mobileView ? 'top' : 'left'}
            >
              <Tabs.TabPane tab={t('my_account.account.tab_name')} key="account" />
              <Tabs.TabPane tab={t('my_account.addresses.tab_name')} key="addresses" />
              <Tabs.TabPane tab={t('my_account.orders.tab_name')} key="orders" />
              <Tabs.TabPane tab={t('my_account.wishlist.tab_name')} key="wishlist" />
            </Tabs>
          </div>
        </Col>

        <Col xs={24} sm={24} md={18} lg={19} xl={20} xxl={20} className="tab-content-wrapper">
          <div className="tab-content">{renderActiveTabContent()}</div>
        </Col>
      </Row>
    </div>
  );
};

export default MyAccount;
