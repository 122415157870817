import { schema } from 'normalizr';

function SchemaCreator(key, relations, options) {
  const entity = new schema.Entity(key, relations, options);
  return {
    entity,
    list: new schema.Array(entity),
  };
}

export const BanksSchema = SchemaCreator('banks');

export const BrandsSchema = SchemaCreator('brands');
export const InstallmentsSchema = SchemaCreator('installments');

export const PromotionsSchema = SchemaCreator('promotions');

export const ProductsSchema = SchemaCreator('products', {
  brand: BrandsSchema.entity,
  category: new schema.Entity('categories'),
});

export const CategorySchema = SchemaCreator('categories', {
  products: ProductsSchema.list,
});

export const AddressesSchema = SchemaCreator('addresses');

export const UsersSchema = SchemaCreator('users', {
  addresses: AddressesSchema.list,
});

export const OrdersSchema = SchemaCreator('orders', {
  address: AddressesSchema.entity,
  user: UsersSchema.entity,
});

export const OrderProduct = SchemaCreator('orderProduct', {
  product: ProductsSchema.entity,
});

export const CartOrderSchema = SchemaCreator('cartOrder', {
  user: UsersSchema.entity,
  address: AddressesSchema.entity,
  order_products: OrderProduct.list,
});

export const PageSettingsSchema = SchemaCreator('pageSettings');

export const BranchesSchema = SchemaCreator('branches');
