import { Typography, Row, Col, Space, List, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageSettingsSelector } from 'redux/pageSettings/selectors';
import { fetchPageSettings } from 'redux/pageSettings/thunks';
import aboutUsImage from 'assets/images/about-us-image.png';
import { useTranslation } from 'react-i18next';

import { fetchBranches } from 'redux/branches/thunks';
import Branches from 'redux/branches';
import BranchItem from './BranchItem';
import './index.scss';

const listGrid = {
  gutter: 10,
  xxs: 1,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 4,
  xxl: 5,
};

const renderItem = (item) => (
  <List.Item>
    <BranchItem id={item} />
  </List.Item>
);
const AboutUs = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [branchesLoading, setBranchesLoading] = useState(true);
  const { t } = useTranslation('about-us');
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { first_title, second_title, first_paragraph, second_paragraph } =
    useSelector(pageSettingsSelector)('about_us') || {};

  const branches = useSelector(Branches.selectors.selectIds);

  useEffect(() => {
    setPageLoading(true);
    dispatch(fetchPageSettings({ params: { group: 'about_us' } })).then(() => setPageLoading(false));
    dispatch(fetchBranches({ params: { show_all: true } })).then(() => {
      setBranchesLoading(false);
    });
  }, [dispatch, i18n.language]);

  return (
    <div className="about-us-wrapper">
      {pageLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <Space direction="vertical">
          <div className="who-we-are-wrapper">
            <Row gutter={[40, 20]} align="top">
              <Col xs={24} sm={24} md={24} lg={16}>
                <span className="who-we-are">{t('who-we-are')}</span>
                <h1 level={2} className="section-title">
                  {first_title?.value}
                </h1>
                <Typography.Text className="section-paragraph">{first_paragraph?.value}</Typography.Text>
              </Col>
              {/* <Col className="image-wrapper" xs={24} sm={24} md={12} justify="center" align="center">
                <img src={aboutUsImage} alt="About Abdul Aziz" />
              </Col> */}
            </Row>
          </div>
          <div className="our-branches-wrapper">
            <h1 level={2} className="section-title">
              {second_title?.value}
            </h1>
            <Typography.Text className="section-paragraph">{second_paragraph?.value}</Typography.Text>
            <List grid={listGrid} dataSource={branches} renderItem={renderItem} loading={branchesLoading} />
          </div>
        </Space>
      )}
    </div>
  );
};

export default AboutUs;
