import { createSlice } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { editProfile } from 'redux/users/thunks';
import { userSignin } from './thunks';
import concat from 'lodash/concat';
import takeRight from 'lodash/takeRight';
import uniq from 'lodash/uniq';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authHeaders: undefined,
    currentUser: undefined,
    recentlyViewed: [],
  },
  reducers: {
    userLogout(state) {
      state.authHeaders = undefined;
      state.currentUser = undefined;
    },
    updateRecentlyViewed(state, action) {
      let updatedItems = concat(state.recentlyViewed, action.payload);
      updatedItems = uniq(updatedItems);
      updatedItems = takeRight(updatedItems, 8);
      state.recentlyViewed = updatedItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userSignin.fulfilled, (state, action) => {
      if (!action.payload.headers || !action.payload.data) return;
      state.authHeaders = pick(action.payload.headers, ['client', 'token', 'uid']);
      state.currentUser = values(action.payload.data.users)[0];
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      if (!action.payload.headers || !action.payload.data) return;
      state.authHeaders = pick(action.payload.headers, ['client', 'token', 'uid']);
      state.currentUser = values(action.payload.data.users)[0];
    });
  },
});
