import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import { fetchBanks } from './thunks';

export const slice = createSlice({
  name: 'banks',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchBanks.fulfilled, (state, action) => {
      if (!action.payload.banks) {
        adapter.removeAll(state);
        return;
      }

      adapter.setAll(state, action.payload.banks);
    });
  },
});

const selectors = {
  ...adapter.getSelectors((state) => state['banks']),
};

const Banks = {
  adapter,
  slice,
  selectors,
};
export default Banks;
