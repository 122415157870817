import { useCallback, useEffect, useState } from 'react';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Menu from 'antd/es/menu';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import Categories from 'redux/categories';
import CartIcon from 'components/molecules/CartIcon';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Favourits } from 'assets/icons/favourits.svg';
import { ReactComponent as FilledFavourits } from 'assets/icons/filled_favourites.svg';
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg';
import Brands from 'redux/brands';
import MultiMenu from 'components/molecules/DropDown/components/index.multi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row, Divider, Space, List } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isSignedInSelector } from 'redux/auth/selectors';
import Promotions from 'redux/promotions';
import './index.scss';

const CategoriesHeader = ({ onCategoryPress }) => {
  const { t, i18n } = useTranslation(['header', 'molecules', 'footer']);
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);

  const mainCategories = useSelector(Categories.selectors.mainCategories);
  const singleCategory = useSelector(Categories.selectors.byId);

  const brands = useSelector(Brands.selectors.selectAll);
  const promotions = useSelector(Promotions.selectors.selectAll);
  const isSignedIn = useSelector(isSignedInSelector);

  const [hovered, setHovered] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);

  const onTitleClick = useCallback(
    (id, type, slug = '', preventNavigation = false) => () => {
      if (preventNavigation) {
        return;
      }
      if (type === 'category') {
        history.push(`/products?category_id=${id}`);
      } else if (type === 'promotion') {
        history.push(`/promotions/${slug}`);
      } else {
        const path = `/${type}`;
        history.push(path);
      }

      setActiveLink({ id: slug || id, type });
    },
    [, history],
  );

  useEffect(() => {
    const categoryID = queryParams.category_id;

    if (categoryID) {
      setActiveLink({ id: categoryID, type: 'category' });
    } else {
      setActiveLink(null);
    }
  }, [location.search, queryParams.category_id]);

  useEffect(() => {
    if (!promotions.length) return;

    const categoryID = queryParams.category_id;
    const brandID = queryParams.brand_id;
    const pathname = location.pathname;

    if (pathname === '/') {
      if (activeLink !== null) {
        setActiveLink(null);
      }
    } else if (categoryID) {
      const currentCategory = singleCategory(categoryID);
      const parentID = currentCategory?.parent_id;

      if (activeLink?.id !== (parentID || categoryID) || activeLink?.type !== 'category') {
        setActiveLink({ id: parentID || categoryID, type: 'category' });
      }
    } else if (pathname.includes('/promotions')) {
      const promotion = promotions.find((promo) => pathname.includes(promo.slug));
      if (promotion && (activeLink?.id !== promotion.id || activeLink?.type !== 'promotion')) {
        setActiveLink({ id: promotion.id, type: 'promotion' });
      }
    } else if (brandID) {
      if (activeLink?.id !== brandID || activeLink?.type !== 'brands') {
        setActiveLink({ id: brandID, type: 'brands' });
      }
    } else {
      const path = pathname.slice(1);
      if (activeLink?.id !== path || activeLink?.type !== path) {
        setActiveLink({ id: path, type: path });
      }
    }
  }, [location.pathname, promotions, queryParams, singleCategory, activeLink]);

  useEffect(() => {
    const handleScroll = () => {
      setOpenKeys([]);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    const categoryID = queryParams.category_id;
    const brandID = queryParams.brand_id;
    const pathname = location.pathname;

    if (pathname === '/') {
      setActiveLink(null);
    } else if (categoryID) {
      setActiveLink({ id: categoryID, type: 'category' });
    } else if (pathname.includes('/promotions')) {
      const promotion = promotions.find((promo) => pathname.includes(promo.slug));
      if (promotion) setActiveLink({ id: promotion.id, type: 'promotion' });
    } else if (brandID) {
      setActiveLink({ id: brandID, type: 'brands' });
    } else if (pathname === '/about-us' || pathname === '/special-offers') {
      setActiveLink({ id: pathname.slice(1), type: pathname.slice(1) });
    } else {
      setActiveLink(null);
    }
  }, [location.pathname, promotions]);

  const onIconClick = () => {
    setActiveLink(null);
  };

  const renderPromotions = () => {
    if (promotions.length < 3)
      return promotions.map((promotion) => (
        <Link
          to={{ pathname: `/promotions/${promotion.slug}`, state: { promotionId: promotion.id } }}
          key={promotion.id}
        >
          <Button
            type="text"
            className={`external-links ${
              activeLink?.type === 'promotion' && activeLink?.id === promotion.id ? 'active' : ''
            }`}
            // onClick={onTitleClick(promotion.id, 'promotion', promotion.slug)}
          >
            {promotion.name}
          </Button>
        </Link>
      ));

    return (
      <Menu.SubMenu
        key={`promotions`}
        title={
          <Button type="text" className={activeLink?.type === 'promotion' ? 'active' : ''}>
            <Typography.Text className="promotionsMenu">
              {t('molecules:header:promotions')}
              <DownArrow className="arrow" />
            </Typography.Text>
          </Button>
        }
      >
        {promotions.map((promotion) => (
          <Row key={promotion.id}>
            <Link to={`/promotions/${promotion.slug}`}>
              <Button type="text">
                <Typography.Text strong>{promotion.name}</Typography.Text>
              </Button>
            </Link>
          </Row>
        ))}
      </Menu.SubMenu>
    );
  };

  return (
    <div className="categories-header">
      <div className="categories-wrapper">
        <Menu mode="horizontal" openKeys={openKeys} onOpenChange={onOpenChange}>
          {mainCategories.map((categoryId) => {
            const category = singleCategory(categoryId);
            const isActive =
              activeLink?.type === 'category' &&
              (categoryId === activeLink?.id || category?.children.some((child) => child.id === activeLink?.id));
            return (
              <Menu.SubMenu
                key={`category_${categoryId}`}
                title={
                  <Button
                    className={`btn-wrapper ${isActive || activeLink?.id === String(categoryId) ? 'active' : ''}`}
                    onClick={onTitleClick(categoryId, 'category')}
                    type="text"
                  >
                    <Typography.Text>{category?.name}</Typography.Text>
                    {category.children.length > 0 && <DownArrow className="arrow" />}
                  </Button>
                }
              >
                {category.children.length > 0 && (
                  <>
                    <div className="categories-title">
                      <Typography>{t('molecules:header:categories')}</Typography>
                      <Divider />
                    </div>
                    <MultiMenu categoryId={categoryId} onCategoryPress={onCategoryPress} />
                  </>
                )}
              </Menu.SubMenu>
            );
          })}
          <Menu.SubMenu
            key={`brands`}
            title={
              <Button
                className={`btn-wrapper brands ${activeLink?.type === 'brands' ? 'active' : ''}`}
                // onClick={onTitleClick(null, 'brands', true)}
                type="text"
              >
                <Typography.Text>{t('molecules:header:brands')}</Typography.Text>
                <DownArrow className="arrow" />
              </Button>
            }
          >
            <div className="categories-title">
              <Typography>{t('molecules:header:brands')}</Typography>
              <Divider />
            </div>
            <Row gutter={30} className="brands-dropdown-menu">
              <Space align="start">
                {brands.map((brand) => (
                  <Col span={8} key={brand.id}>
                    <List
                      split={false}
                      itemLayout="vertical"
                      dataSource={[brand]}
                      renderItem={(brand) => (
                        <List.Item key={`brand_${brand.id}`}>
                          <Row>
                            <Link to={`/products?${queryString.stringify({ brand_id: brand.id })}`}>
                              <Button
                                type="text"
                                // onClick={onTitleClick(brand.id, 'brands')}
                              >
                                <Typography.Text>
                                  {i18n.language === 'ar' && brand.name_ar ? brand.name_ar : brand.name_en}
                                </Typography.Text>
                              </Button>
                            </Link>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Col>
                ))}
              </Space>
            </Row>
          </Menu.SubMenu>
          <Link to="/special-offers">
            <Button
              type="text"
              className={`external-links ${activeLink?.type === 'special-offers' ? 'active' : ''}`}
              // onClick={onTitleClick('special-offers', 'special-offers')}
            >
              {t('footer:special')}
            </Button>
          </Link>
          {renderPromotions()}

          <Link to="/about-us">
            <Button
              type="text"
              className={`external-links ${activeLink?.type === 'about-us' ? 'active' : ''}`}
              // onClick={onTitleClick('about-us', 'about-us')}
            >
              {t('molecules:header:about_us')}
            </Button>
          </Link>
        </Menu>
      </div>
      <div lg={4} className="cart-wrapper">
        {isSignedIn && (
          <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Link className="favourite-icon" to="/my-account/wishlist" onClick={onIconClick}>
              {hovered ? <FilledFavourits /> : <Favourits />}
            </Link>
          </div>
        )}
        <CartIcon link="/checkout/cart" onClick={onIconClick} />
        {isSignedIn && (
          <Link to="/my-account/account">
            <User className="user-icon" link="/my-account/account" onClick={onIconClick} />
          </Link>
        )}
      </div>
    </div>
  );
};

CategoriesHeader.propTypes = {
  onCategoryPress: PropTypes.func,
};

export default CategoriesHeader;
