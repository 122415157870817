import { useCallback } from 'react';
import { Typography, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import DropDownMenu from 'components/molecules/DropDown';
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg';
import { isSignedInSelector } from 'redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { authSlice } from 'redux/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { FacebookFilled, InstagramFilled, YoutubeFilled, WhatsAppOutlined } from '@ant-design/icons';
import './index.scss';
import Cart from 'redux/cart';

const ProfileHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('authentication');
  const { t: globalTranslation } = useTranslation('global');
  const { t: moleculeTranslation } = useTranslation('molecules');

  const { i18n } = useTranslation();
  const isSignedIn = useSelector(isSignedInSelector);

  const history = useHistory();
  const location = useLocation();

  const onLanguageChange = useCallback(() => {
    const currentPath = location.pathname + location.search;

    if (i18n.language === 'ar') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ar');
    }

    history.replace(currentPath);
  }, [i18n, history, location]);

  const logout = () => {
    dispatch(authSlice.actions.userLogout());
    dispatch(Cart.slice.actions.resetCart());
  };

  return (
    <div className="profile-header">
      <div>
        <Typography.Text>{t('welcome-to-abd-elaziz-online-store')}</Typography.Text>
      </div>
      <div className="social-media-wrapper">
        <div className="social-media">
          <span>{t('follow-us')}</span>
          {[
            // {
            //   href: 'https://api.whatsapp.com/send?phone=201120777715',
            //   icon: <WhatsAppOutlined />,
            //   text: t('whatsapp'),
            // },
            { href: 'https://www.facebook.com/Abdulazizstores/', icon: <FacebookFilled />, text: t('facebook') },
            {
              href: 'https://www.instagram.com/abdulazizstores/',
              icon: <InstagramFilled />,
              text: t('instagram'),
            },
            {
              href: 'https://www.youtube.com/channel/UCRd2YBDJG5Sq8zeg57jj22w',
              icon: <YoutubeFilled />,
              text: t('youtube'),
            },
          ].map(({ href, icon, text }) => (
            <div>
              <Button key={href + text} target="_blank" href={href} type="text">
                {icon}
              </Button>
            </div>
          ))}
        </div>
        {isSignedIn && <Divider type="vertical" />} {/* Remove the condition when add switcher button */}
        {/* <DropDownMenu
          items={[{ label: i18n.language === 'ar' ? 'English' : 'عربي', link: '/' }]}
          onClick={onLanguageChange}
          className="language-dropdown"
        >
          <Button className="btn-wrapper" type="text">
            <Typography.Text>{globalTranslation('language')}</Typography.Text>
            <DownArrow />
          </Button>
        </DropDownMenu> */}
        {isSignedIn && (
          <Button type="text" onClick={logout}>
            <Typography.Text>{moleculeTranslation('header.profile_dropdown.logout')}</Typography.Text>
          </Button>
        )}
      </div>
    </div>
  );
};
export default ProfileHeader;
