import { Col, Row, Typography } from 'antd';
import './index.scss';

const OrderDetails = ({ title, details }) => (
  <Row className="order-details-item">
    <Col xs={24} sm={24} m={24} lg={24} xl={24} xxl={24}>
      <Typography.Text strong>{title}</Typography.Text>
    </Col>
    <Col xs={24} sm={24} m={24} lg={24} xl={24} xxl={24}>
      {details}
    </Col>
  </Row>
);

export default OrderDetails;
