import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { BanksSchema } from 'redux/schema';
import { BanksAPI } from './services';

export const fetchBanks = createAsyncThunk('banks/fetchAll', async ({ params }, { extra }) => {
  const response = await BanksAPI.fetchAll({ params });
  const normalized = normalize(response.data.banks, BanksSchema.list);
  return extra.withPaginationMeta(response, normalized.entities);
});
