import { unwrapResult } from '@reduxjs/toolkit';
import { Row, Col, Form, Input, Button, Modal, Typography } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { userForgotPassword } from 'redux/auth/thunks';
import Logo from 'assets/images/Abdelaziz-stores-logo.png';

const ForgotPassword = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isMounted = useMountedState();

  const { t: authenticationTranslation } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(userForgotPassword(values))
      .then(unwrapResult)
      .then(() => {
        Modal.success({
          title: screenTranslation('forgot_password.feedback.success.title'),
          content: screenTranslation('forgot_password.feedback.success.content'),
          okText: screenTranslation('forgot_password.feedback.success.ok'),
          onOk: () => {
            history.push('/auth/otp_confirmation', {
              previous: 'forgot_password',
              phone_number: values.phone_number,
            });
          },
        });
      })
      .catch((error) => {
        if (error?.status === 404) {
          form.setFields([
            {
              name: 'phone_number',
              errors: [screenTranslation('forgot_password.feedback.errors.404')],
            },
          ]);
        } else {
          Modal.error({
            title: screenTranslation('forgot_password.feedback.errors.error'),
            content: (
              <>
                {error?.data?.error?.map((error) => (
                  <Typography.Text key={error}>{error}</Typography.Text>
                ))}
              </>
            ),
          });
        }
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  return (
    <div className="auth-wrapper">
      <Row className="header-wrapper">
        <a href="/">
          <img className="logo" src={Logo} alt="Abdul Aziz Logo" />
        </a>
      </Row>
      <Row className="tabs-wrapper" gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={14} xxl={14}>
          <div className="banner-wrapper">
            <>
              <span>{screenTranslation('forgot_password.title')}</span>
              <span className="subtitle">{screenTranslation('forgot_password.description')}</span>
            </>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xxl={10}>
          <BorderedSection className="section-radius">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form form={form} onFinish={onSubmit}>
                  <Form.Item
                    rules={[
                      { required: true, message: screenTranslation('forgot_password.form.phone_number_validation') },
                    ]}
                    colon={false}
                    name="phone_number"
                    label={screenTranslation('forgot_password.form.phone_number')}
                  >
                    <Input placeholder={screenTranslation('forgot_password.form.phone_number_placeholder')} />
                  </Form.Item>

                  <Form.Item>
                    <Button loading={loading} htmlType="submit" type="primary">
                      {authenticationTranslation('forgot_password')}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </BorderedSection>
        </Col>
      </Row>
      <span className="copyright">{authenticationTranslation('copyright')}</span>
    </div>
  );
};

export default ForgotPassword;
