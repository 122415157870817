import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import { fetchInstallments } from './thunks';

export const slice = createSlice({
  name: 'installments',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchInstallments.fulfilled, (state, action) => {
      // if (!action.payload.installments) return;
      adapter.setAll(state, action.payload?.installments || {});
    });
  },
});

const selectors = {
  ...adapter.getSelectors((state) => state.installments),
};

const Installments = {
  adapter,
  slice,
  selectors,
};
export default Installments;
