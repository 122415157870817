import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { ProductsSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import ProductsAPI from './services';
import store from 'redux/store';

export const fetchProducts = createPaginatedAsyncThunk('products/fetchAll', async ({ params = {} }, { extra }) => {
  const response = isEmpty(store.getState().auth.authHeaders)
    ? await ProductsAPI.fetchAll(params)
    : await ProductsAPI.fetchUserProducts(params);
  const normalized = normalize(response.data.products, ProductsSchema.list);
  return extra.withPaginationMeta(response, normalized);
});

export const fetchUserProducts = createPaginatedAsyncThunk(
  'products/fetchUserProducts',
  async ({ params = {} }, { extra }) => {
    const response = await ProductsAPI.fetchUserProducts(params);
    const favorites = response.data.products.map((product) => assign(product, { favorite: true }));
    const normalized = normalize(favorites, ProductsSchema.list);
    return extra.withPaginationMeta(response, normalized);
  },
);

export const fetchProduct = createAsyncThunk('products/fetch/id', async ({ id }, thunkAPI) => {
  try {
    const response = isEmpty(store.getState().auth.authHeaders)
      ? await ProductsAPI.fetchById(id)
      : await ProductsAPI.fetchUserProductById(id);
    const normalized = normalize(response.data.product, ProductsSchema.entity);
    return normalized;
  } catch (error) {
    return thunkAPI.rejectWithValue({ data: error.data, status: error.status });
  }
});

export const favoriteProduct = createAsyncThunk('products/favorite/id', async ({ id }, thunkAPI) => {
  try {
    const response = await ProductsAPI.addToFavorite(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const unfavoriteProduct = createAsyncThunk('products/unfavorite/id', async ({ id }, thunkAPI) => {
  try {
    await ProductsAPI.removeFromFavorite(id);
    return id;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
