import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetchOrders } from 'redux/orders/thunks';
import { ReactComponent as ViewIcon } from 'assets/icons/view.svg';
import localizeNumber from 'utils/localizeNumber';
import EntityPagination from 'components/atoms/Pagination';
import { Table, Typography } from 'antd';
import Orders from 'redux/orders';
import './index.scss';

const OrdersTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t, i18n } = useTranslation('screens');
  const { t: globalTranslation } = useTranslation('global');
  const locale = i18n.language;

  const [loading, setLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({ defaultPageSize: 10, total: 100 });
  const [displayedPage, setDisplayPage] = useState(1);

  const ordersMeta = useSelector((state) => state._pagination[`orders/fetchAll`]);
  const allOrders = useSelector(Orders.selectors.selectAll);

  const getTranslatedPaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return t('payment_success.payment_method.cash');
      case 'online':
        return t('payment_success.payment_method.online');
      case 'visa_on_delivery':
        return t('payment_success.payment_method.visa_on_delivery');
      case 'visa_on_delivery_installments':
        return t('payment_success.payment_method.visa_on_delivery_installments');
      default:
        return t('payment_success.payment_method.unknown');
    }
  };

  const getTranslatedStatus = (status) => {
    switch (status) {
      case 'pending':
        return t('my_account.orders.table.status_options.pending');
      case 'confirmed':
        return t('my_account.orders.table.status_options.confirmed');
      case 'processing':
        return t('my_account.orders.table.status_options.processing');
      case 'delivering':
        return t('my_account.orders.table.status_options.delivering');
      case 'canceled':
        return t('my_account.orders.table.status_options.canceled');
      case 'delivered':
        return t('my_account.orders.table.status_options.delivered');
      case 'refunded':
        return t('my_account.orders.table.status_options.refunded');
      case 'partially_refunded':
        return t('my_account.orders.table.status_options.partially_refunded');
      default:
        return t('my_account.orders.table.status_options.unknown');
    }
  };

  const dataSource = allOrders.map((order) => ({
    key: order.id,
    order_id: order.id,
    checkout_date:
      order.checkout_date &&
      new Intl.DateTimeFormat(locale === 'ar' ? 'ar-EG' : 'en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(new Date(order.checkout_date)),
    payment_method: getTranslatedPaymentMethod(order.payment_method),
    status: getTranslatedStatus(order.status),
    checkout_total_price: `${localizeNumber(order.checkout_total_price)} ${globalTranslation('currency')}`,
    actions: (
      <div className="action-btn" onClick={() => handleViewOrder(order.id)}>
        {t('my_account.orders.table.view_details')}
        <ViewIcon />
      </div>
    ),
  }));

  const columns = [
    {
      title: t('my_account.orders.table.order_number'),
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: t('my_account.orders.table.checkout_date'),
      dataIndex: 'checkout_date',
      key: 'checkout_date',
    },
    {
      title: t('my_account.orders.table.payment_method'),
      dataIndex: 'payment_method',
      key: 'payment_method',
    },
    {
      title: t('my_account.orders.table.status'),
      dataIndex: 'status',
      key: 'status',

      onCell: (record) => {
        let backgroundColor;
        if (record.status === 'Pending' || record.status === 'قيد الانتظار') {
          backgroundColor = '#fae9af';
        } else if (record.status === 'Confirmed' || record.status === 'مؤكد') {
          backgroundColor = '#dafaaf';
        } else if (record.status === 'Processing' || record.status === 'قيد المعالجة') {
          backgroundColor = '#f0f0f0';
        } else if (record.status === 'Delivering' || record.status === 'في طريق التسليم') {
          backgroundColor = '#daa8fa';
        } else if (record.status === 'Canceled' || record.status === 'ملغي') {
          backgroundColor = '#f4c6cc';
        } else if (record.status === 'Delivered' || record.status === 'تم التسليم') {
          backgroundColor = '#b2faa8';
        } else if (record.status === 'Refunded' || record.status === 'مسترد') {
          backgroundColor = '#556b2f';
        } else if (record.status === 'Partially Refunded' || record.status === 'مسترد جزئياً') {
          backgroundColor = '#BC8F8F';
        }
        return {
          style: {
            backgroundColor,
          },
        };
      },
      render: (status) => `${status}`,
    },
    {
      title: t('my_account.orders.table.checkout_total_price'),
      dataIndex: 'checkout_total_price',
      key: 'checkout_total_price',
    },
    {
      title: t('my_account.orders.table.actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(fetchOrders({ params: { per_page: 10, page: displayedPage }, refresh: true })).then(() => {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [dispatch, displayedPage]);

  useEffect(() => {
    setPaginationInfo({ defaultPageSize: ordersMeta?.per_page, total: ordersMeta?.total_entries });
  }, [ordersMeta]);

  const handleViewOrder = (orderId) => {
    history.push(`/my-account/orders/${orderId}`);
  };

  return (
    <div className="order-tab">
      <Typography className="orders-title">{t('my_account.orders.tab_name')}</Typography>

      <Table dataSource={dataSource} columns={columns} loading={loading} pagination={false} />
      <EntityPagination
        current={displayedPage}
        onChange={setDisplayPage}
        defaultPageSize={paginationInfo.defaultPageSize}
        total={paginationInfo.total}
      />
    </div>
  );
};
export default OrdersTab;
