import AddressesList from 'components/organsims/AddressesList';
import './index.scss';

const ChooseAddress = ({ onPickAddress }) => {
  return (
    <div className="choose-address-wrapper">
      <AddressesList onPickAddress={onPickAddress} />
    </div>
  );
};
export default ChooseAddress;
