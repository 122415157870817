import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import selectors from './selectors';
import adapter from './adapter';

export const slice = createSlice({
  name: 'categories',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(thunks.fetchCategories.fulfilled, (state, action) => {
      if (!action.payload?.categories) return;
      adapter.upsertMany(state, action.payload?.categories);
    });
  },
});
const Categories = {
  slice,
  thunks,
  selectors,
};
export default Categories;
