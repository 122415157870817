import { createSelector } from '@reduxjs/toolkit';
import { clone } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import reverse from 'lodash/reverse';

export const isSignedInSelector = createSelector(
  (store) => store.auth.authHeaders,
  (authHeaders) => !isEmpty(authHeaders),
);
export const recentyViewedSelector = createSelector(
  (store) => store.auth.recentlyViewed,
  (recentlyViewed) => {
    const products = clone(recentlyViewed);
    reverse(products);
    return products;
  },
);
