import { useState } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Cart } from 'assets/icons/cart.svg';
import { ReactComponent as FilledCart } from 'assets/icons/filled_cart.svg';

import { useSelector } from 'react-redux';
import { cartCounter } from 'redux/cart/selectors';
import './index.scss';

const CartIcon = ({ link, onClick }) => {
  const count = useSelector(cartCounter);
  const [hovered, setHovered] = useState(false);

  return (
    <Link className="cart-link-button" to={link} onClick={onClick}>
      <span className="cart-icon-wrapper">
        <span
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="cart-icon-container"
        >
          {hovered ? <FilledCart /> : <Cart />}
        </span>
        {count > 0 && <Tag>{count}</Tag>}
      </span>
    </Link>
  );
};

CartIcon.PropType = {
  link: PropTypes.string,
  numberOfItems: PropTypes.number,
};

export default CartIcon;
