import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { withPaginationMeta } from './utils/createPaginatedAsyncThunk';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import axios from 'axios';
import assign from 'lodash/assign';
import { authSlice } from 'redux/auth';

import set from 'lodash/set';
import i18n from 'i18next';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'payments'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    thunk: {
      extraArgument: { withPaginationMeta },
    },
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

// Setting up axios configs
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  const headers = store.getState().auth.authHeaders;
  assign(config.headers, headers);
  return config;
});

axios.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    assign(config.headers, {
      'Content-Type': 'multipart/form-data',
    });
  }
  return config;
});

const injectLocale = (request) => {
  set(request, ['params', 'locale'], i18n.language);
  return request;
};

axios.interceptors.request.use(injectLocale);

axios.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401) store.dispatch(authSlice.actions.userLogout());
  return Promise.reject(error?.response);
});

export const persistor = persistStore(store);
export default store;
