import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProducts } from 'redux/products/thunks';
import Products from 'redux/products';
import { useTranslation } from 'react-i18next';
import EntityPagination from 'components/atoms/Pagination';
import { List, Typography } from 'antd';
import WishlistProductPreview from 'components/molecules/WishlistProductPreview';
import './index.scss';

const WishlistTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('screens');

  const [displayedPage, setDisplayPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState({ defaultPageSize: 10, total: 100 });
  const productsMeta = useSelector((state) => state._pagination[`products/fetchUserProducts?favorites=true`]);
  const favoriteProducts = useSelector((state) =>
    Products.selectors.selectFavorites(state, displayedPage, paginationInfo.defaultPageSize),
  );

  useEffect(() => {
    dispatch(fetchUserProducts({ params: { favorites: true, per_page: 10, page: displayedPage }, refresh: true })).then(
      () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      },
    );
  }, [dispatch, displayedPage]);

  useEffect(() => {
    setPaginationInfo({ defaultPageSize: productsMeta?.per_page, total: productsMeta?.total_entries });
  }, [productsMeta]);

  const renderItem = (item) => <WishlistProductPreview productId={item} isFavorite />;

  return (
    <div className="wishlist-wrapper">
      <Typography className="wishlist-title">{t('my_account.wishlist.tab_name')}</Typography>
      <List
        className="wishlist"
        dataSource={favoriteProducts}
        renderItem={renderItem}
        split={false}
        pagination={false}
      />
      <EntityPagination
        current={displayedPage}
        onChange={setDisplayPage}
        defaultPageSize={paginationInfo.defaultPageSize}
        total={paginationInfo.total}
      />
    </div>
  );
};

export default WishlistTab;
