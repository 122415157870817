import { createSelector } from '@reduxjs/toolkit';

export const pageSettingsSelector = createSelector(
  (state) => state?.pageSettings?.page_settings,
  (pageSettings) => (groupName) => pageSettings[groupName],
);

const selectors = {
  pageSettingsSelector,
};

export default selectors;
