import { Form, Col, Row } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';
import Modal from 'antd/es/modal';
import { useTranslation } from 'react-i18next';
import BorderedSection from 'components/organsims/BorderedSection';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { userResetPassword } from 'redux/auth/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import entries from 'lodash/entries';
import Logo from 'assets/images/Abdelaziz-stores-logo.png';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xxl: { span: 24 },
  },
};
const ResetPassword = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('screens');
  const { t: authTrans } = useTranslation('authentication');
  const { token } = useParams();
  const dispatch = useDispatch();

  const onFinish = (data) => {
    dispatch(userResetPassword({ sms_password_reset_otp: token, ...data }))
      .then(unwrapResult)
      .then(() => {
        history.push('/auth/signin');
      })
      .catch((error) => {
        if (error.data?.error) {
          Modal.error({
            title: t('forgot_password.feedback.errors.error'),
            content: (
              <>
                {error?.data?.error?.map((error) => (
                  <Typography.Text key={error}>{error}</Typography.Text>
                ))}
              </>
            ),
          });
        } else if (error?.data) {
          entries(error.data)?.forEach(([name, errors]) => {
            form.setFields([
              {
                name,
                errors,
              },
            ]);
          });
        }
      });
  };

  return (
    <div className="auth-wrapper">
      <Row className="header-wrapper">
        <a href="/">
          <img className="logo" src={Logo} alt="Abdul Aziz Logo" />
        </a>
      </Row>
      <Row className="tabs-wrapper" gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={14} xxl={14}>
          <div className="banner-wrapper">
            <>
              <span>{t('change-password.title')}</span>
              <span className="subtitle">{t('change-password.description')}</span>
            </>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xxl={10}>
          <BorderedSection className="section-radius">
            <Form form={form} name="change_password" onFinish={onFinish} layout="vertical" {...formItemLayout}>
              <Form.Item
                name="password"
                label={t('change-password.newPassword')}
                rules={[
                  {
                    required: true,
                    message: t('change-password.new_password_validation'),
                  },
                ]}
              >
                <Input.Password placeholder={t('change-password.new_password_placeholder')} />
              </Form.Item>
              <Form.Item
                name="password_confirmation"
                label={t('change-password.confirmPassword')}
                rules={[
                  {
                    required: true,
                    message: t('change-password.confirm_password_validation'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('change-password.password_confirmation_matching'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('change-password.password_confirmation_placeholder')} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {authTrans('reset_password')}
                </Button>
              </Form.Item>
            </Form>
          </BorderedSection>
        </Col>
      </Row>
      <span className="copyright">{authTrans('copyright')}</span>
    </div>
  );
};
export default ResetPassword;
