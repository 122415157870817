import axios from 'axios';

const fetchAll = ({ params }) => axios.get('v1/brands', { params });
const fetchById = ({ id }) => axios.get(`v1/brands/${id}`);

const BrandsAPI = {
  fetchAll,
  fetchById,
};

export default BrandsAPI;
