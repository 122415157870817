import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SingleMenu = ({ items, localePath = '', onClick }) => {
  const { t } = useTranslation(localePath);

  return (
    <Menu onClick={onClick}>
      {items?.map((item) => (
        <Menu.Item key={item.key || item.link}>
          <Link to={item.link}>{t(item.label)}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

SingleMenu.PropType = {
  items: PropTypes.array,
  localePath: PropTypes.string,
};
export default SingleMenu;
