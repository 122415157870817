import axios from 'axios';

const changePassword = (data) => axios.put('v1/users/password', data);

const editProfile = (data) => axios.put('/v1/users', data);

const UsersAPI = {
  changePassword,
  editProfile,
};

export default UsersAPI;
