import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import values from 'lodash/values';
import { addToCart, fetchCart, removeFromCart } from 'redux/cart/thunks';
import { createPayment } from './thunks';

export const slice = createSlice({
  name: 'payments',
  initialState: {
    paymentKey: undefined,
    checkoutPrice: undefined,
  },
  reducers: {
    resetPayment(state, _) {
      state.paymentKey = undefined;
      state.checkoutPrice = undefined;
      state.order_id = undefined;
      state.paymentKeyExpirationTimestamp = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.fulfilled, (state, action) => {
        if (!action.payload.payment_key) return;
        state.paymentKey = action.payload.payment_key;
        state.paymentKeyExpirationTimestamp = Date.now() + process.env.REACT_APP_PAYMENT_TIMEOUT * 60 * 1000;
        if (!action.payload.checkout_total_price) return;
        state.checkoutPrice = action.payload.checkout_total_price;
      })
      .addMatcher(isAnyOf(addToCart.fulfilled, fetchCart.fulfilled, removeFromCart.fulfilled), (state, action) => {
        if (!action.payload.cartOrder) return;
        state.order_id = values(action.payload.cartOrder)[0].id;
      });
  },
});

const Payments = {
  slice,
};

export default Payments;
