import React, { useState, useCallback } from 'react';
import { InputNumber, Button, Space, Modal } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './index.scss';

const ItemCounter = ({
  defaultValue = 1,
  maximumNumber,
  onValueChange,
  stockMaxValue,
  orderMaxValue,
  onRemoveProduct,
}) => {
  const [countValue, setCountValue] = useState(defaultValue);

  const { t } = useTranslation('product-preview');

  const onValidChange = useCallback(
    (newValue) => {
      if (newValue < 0) {
        setCountValue(1);
        if (countValue !== 1) onValueChange(1);
      } else if (newValue === 0) {
        onRemoveProduct();
      } else if (
        newValue &&
        newValue <= (orderMaxValue !== null ? Math.min(stockMaxValue, orderMaxValue) : stockMaxValue)
      ) {
        setCountValue(newValue);
        onValueChange(newValue);
      } else if (newValue > stockMaxValue)
        Modal.warning({ content: t('warning-stock-max', { maxValue: stockMaxValue }) });
      else if (newValue > orderMaxValue)
        Modal.warning({ content: t('warning-order-max', { maxValue: orderMaxValue }) });
    },
    [onRemoveProduct, stockMaxValue, orderMaxValue, t, countValue, onValueChange],
  );

  const onAddPress = useCallback(() => {
    onValidChange(parseInt(countValue) + 1);
  }, [countValue, onValidChange]);

  const onSubtractPress = useCallback(() => {
    onValidChange(countValue - 1);
  }, [countValue, onValidChange]);

  const onChange = useCallback(
    (value) => {
      onValidChange(value);
    },
    [onValidChange],
  );

  return (
    <div className="counter-container">
      <Space size="small">
        <Button className="counter-btn plus-btn" icon={<PlusOutlined />} size="small" onClick={onAddPress} />
        <InputNumber
          className="input-wrapper"
          min={1}
          max={orderMaxValue !== null ? Math.min(stockMaxValue, orderMaxValue) : stockMaxValue}
          size="small"
          value={countValue}
          onChange={onChange}
        />
        <Button className="counter-btn minus-btn" icon={<MinusOutlined />} size="small" onClick={onSubtractPress} />
      </Space>
    </div>
  );
};

ItemCounter.propTypes = {
  defaultValue: PropTypes.number,
  onValueChange: PropTypes.func,
};
export default ItemCounter;
