import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentsAPI from './services';

export const createPayment = createAsyncThunk('payments/create', async (_, thunkAPI) => {
  try {
    const response = await PaymentsAPI.createPayment({ payment_method: 'online' });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const submitCheckout = createAsyncThunk('payments/create', async (data, thunkAPI) => {
  try {
    const response = await PaymentsAPI.submitCashPayment(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
