import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Form, Input, Button, Typography, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { userSignin } from 'redux/auth/thunks';

const SignIn = ({ history }) => {
  const form = Form.useForm();
  const { t } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(userSignin(values))
      .then(unwrapResult)
      .catch((errors) => {
        if (errors?.data?.need_confirmation)
          Modal.error({
            title: screenTranslation('signin.errors.title'),
            okText: screenTranslation('signin.errors.otp'),
            content: (
              <>
                {errors?.data?.errors?.map((error) => (
                  <Typography.Text>{error}</Typography.Text>
                ))}
              </>
            ),
            onOk: () => {
              history.push('/auth/otp_confirmation', {
                previous: 'signin',
                phone_number: values.phone_number,
              });
            },
          });
        else if (errors.status === 401) {
          Modal.error({
            title: screenTranslation('signin.errors.title'),
            okText: screenTranslation('signin.errors.ok'),
            content: screenTranslation('signin.errors.invalid_credentials'),
          });
        } else
          Modal.error({
            title: screenTranslation('signin.errors.title'),
            okText: screenTranslation('signin.errors.ok'),
            content: (
              <>
                {errors?.data?.errors?.map((error) => (
                  <Typography.Text>{error}</Typography.Text>
                ))}
              </>
            ),
          });
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  const onForgotPassword = () => {
    history.push('/auth/forgot_password');
  };

  return (
    <div className="form-wrapper">
      <Form {...form} onFinish={onSubmit}>
        <Col gutter={20}>
          <Form.Item
            rules={[{ required: true, message: screenTranslation('signin.form.phone_number_validation') }]}
            colon={false}
            name="phone_number"
            label={screenTranslation('signin.form.phone_number')}
          >
            <Input placeholder={screenTranslation('signin.form.phone_number_placeholder')} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: screenTranslation('signin.form.password_validation') }]}
            colon={false}
            name="password"
            label={screenTranslation('signin.form.password')}
          >
            <Input.Password placeholder={screenTranslation('signin.form.password_placeholder')} />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} htmlType="submit" type="primary">
              {t('signin')}
            </Button>
          </Form.Item>
          <Button className="forget-password-btn" onClick={onForgotPassword} type="text">
            {t('forgot_password')}
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default SignIn;
