import { Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import './index.scss';

const BorderedSection = ({ title, icon, description, children, level, type, extraIconTitle, className }) => (
  <div className={`bordered-section-wrapper ${className}`}>
    <div className="icon-title-wrapper">
      {icon && icon}
      {title && (
        <Typography.Title strong level={level} type={type}>
          <Space size={10}>
            {extraIconTitle}
            {title}
          </Space>
        </Typography.Title>
      )}
    </div>
    {description && <Typography.Text>{description}</Typography.Text>}
    <div className="section-body">{children}</div>
  </div>
);

BorderedSection.PropType = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  level: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  extraIconTitle: PropTypes.node,
};

export default BorderedSection;
