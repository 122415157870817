import { List } from 'antd';
import ProductCard from 'components/molecules/ProductCard';
import { useMedia } from 'react-use';
import { useLocation } from 'react-router-dom';

const renderItem = (item) => (
  <List.Item>
    <ProductCard productId={item} />
  </List.Item>
);

const ProductsList = ({ products, loading, columns = 3 }) => {
  const labtopView = useMedia('(max-width: 1600px)');
  const extraLargeView = useMedia('(min-width: 1650px)');
  const location = useLocation();

  const isOnProductsPage = location.pathname === '/products';
  const listGrid = {
    gutter: labtopView ? 15 : 20,
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: columns,
    xxl: !isOnProductsPage && extraLargeView ? 5 : 4,
  };

  return <List grid={listGrid} dataSource={products} renderItem={renderItem} loading={loading} />;
};

export default ProductsList;
