import { Fragment, useState } from 'react';
import { Button, Form, Input, Typography, Modal, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changePassword } from 'redux/users/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { authSlice } from 'redux/auth';
import Cart from 'redux/cart';
import './index.scss';

const colSpacing = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 14,
  xxl: 16,
};
const ChangePassword = ({ onBack }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('screens');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = (data) => {
    setLoading(true);
    dispatch(changePassword(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(authSlice.actions.userLogout());
        dispatch(Cart.slice.actions.resetCart());
        Modal.success({
          title: t('change-password.success.title'),
          content: t('change-password.success.content'),
          okText: t('change-password.success.ok'),
        });
      })
      .catch((errors) => {
        Modal.error({
          title: t('change-password.errors.title'),
          okText: t('change-password.errors.ok'),
          content: (
            <>
              {errors?.errors?.full_messages?.map((error) => (
                <Fragment>
                  <Typography.Text>{error}</Typography.Text>
                  <br></br>
                </Fragment>
              ))}
            </>
          ),
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="change-password-wrapper">
        <ArrowLeftOutlined onClick={onBack} />
        <Typography className="change-password-title">{t('change-password.title')}</Typography>
      </div>
      <Form form={form} name="change_password" onFinish={onFinish} layout="vertical">
        <Row gutter={50}>
          <Col {...colSpacing}>
            <Form.Item
              name="current_password"
              label={t('change-password.password')}
              rules={[
                {
                  required: true,
                  message: t('change-password.password_validation'),
                },
              ]}
            >
              <Input.Password placeholder={t('change-password.password_placeholder')} />
            </Form.Item>
            <Form.Item
              name="password"
              label={t('change-password.newPassword')}
              rules={[
                {
                  required: true,
                  message: t('change-password.new_password_validation'),
                },
              ]}
            >
              <Input.Password placeholder={t('change-password.new_password_placeholder')} />
            </Form.Item>
            <Form.Item
              name="password_confirmation"
              label={t('change-password.confirmPassword')}
              rules={[
                {
                  required: true,
                  message: t('change-password.confirm_password_validation'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('change-password.password_confirmation_matching'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t('change-password.password_confirmation_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={4} xl={4} xxl={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('change-password.submit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default ChangePassword;
