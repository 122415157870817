import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Dropdown, Button, Menu, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownArrowDarkBlue } from 'assets/icons/DownArrowDarkBlue.svg';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.scss';

const sortingOptions = [
  { key: 'default', label: 'default' },
  { key: 'lowest-price', label: 'lowest-price' },
  { key: 'highest-price', label: 'highest-price' },
  { key: 'latest', label: 'latest' },
  { key: 'featured', label: 'featured' },
];

const ProductSort = React.memo(({ onSortBy }) => {
  const { t } = useTranslation('products-sort');
  const [selectedSort, setSelectedSort] = useState('sort-by');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { search } = useLocation();

  /**reset filters when search changes */
  useEffect(() => {
    setSelectedSort('sort-by');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleMenuClick = useCallback(
    ({ key }) => {
      key === 'default' ? setSelectedSort('sort-by') : setSelectedSort(key);
      if (onSortBy) onSortBy(key);
      setDropdownVisible(false);
    },
    [onSortBy],
  );

  const handleScroll = useCallback(() => {
    if (dropdownVisible) {
      setDropdownVisible(false);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dropdownVisible, handleScroll]);

  const renderSortingMenu = useMemo(
    () => (
      <Menu onClick={handleMenuClick} className="sort-by-wrapper">
        {sortingOptions.map(({ key, label }) => (
          <Menu.Item key={key}>
            <Typography.Text>{t(label)}</Typography.Text>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [handleMenuClick, t],
  );

  const sortButton = useMemo(
    () => (
      <Button className="sort-btn">
        <Typography.Text>{t(selectedSort)}</Typography.Text>
        <DownArrowDarkBlue />
      </Button>
    ),
    [selectedSort, t],
  );

  return (
    <Dropdown
      overlay={renderSortingMenu}
      visible={dropdownVisible}
      onVisibleChange={setDropdownVisible}
      trigger={['click']}
    >
      {sortButton}
    </Dropdown>
  );
});

ProductSort.propTypes = {
  onSortBy: PropTypes.func,
};

export default ProductSort;
