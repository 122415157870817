import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { Button, Result } from 'antd';
import DefaultLayout from 'layouts/DefaultLayout';

import { isSignedInSelector } from 'redux/auth/selectors';

import UserRoute from './UserRoute';
import GuestRoute from './GuestRoute';

import ForgotPassword from 'screens/Authentication/ForgotPassword';
import ResetPassword from 'screens/Authentication/ResetPassword';

import Cart from 'screens/Guest/Cart';

import HomePage from 'screens/Guest/Homepage';
import AboutUs from 'screens/Guest/AboutUs';
import Products from 'screens/Guest/Products';

import Product from 'screens/Guest/Product';
import MyAccount from 'screens/MyAccount';
import Checkout from 'screens/Checkout';

import Payment from 'screens/Payment';
import OTP from 'screens/Authentication/OTP';

import AddressScreen from 'screens/AddressScreen';
import TermsConditions from 'screens/Guest/TermsConditions';
import PrivacyPolicy from 'screens/Guest/PrivacyPolicy';
import TabGroup from 'screens/Authentication/TabGroup';
import SingleOrder from 'screens/MyAccount/components/MyAccountTabs/SingleOrder';

export const history = createBrowserHistory();

const Router = () => {
  const { pathname } = useLocation();
  const isSignedIn = useSelector(isSignedInSelector);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <DefaultLayout>
      <Route
        render={() => (
          <Switch>
            <UserRoute exact path="/my-account/:tab(account|addresses|wishlist|orders)?" component={MyAccount} />
            <UserRoute exact path="/checkout/cart" component={Checkout} />
            <UserRoute exact path="/checkout/shipping" component={Checkout} />
            <UserRoute exact path="/checkout/national-id" component={Checkout} />
            <UserRoute exact path="/checkout/payment" component={Checkout} />
            <UserRoute exact path="/address/:addressId?" component={AddressScreen} />
            <UserRoute path="/my-account/account/change-password" component={MyAccount} />
            <UserRoute exact path="/my-account/account/edit-profile" component={MyAccount} />
            <UserRoute path="/complete-payment" component={Payment} />
            <UserRoute exact path="/my-account/orders/:id" component={SingleOrder} />

            <GuestRoute exact path="/cart" component={Cart} />
            <GuestRoute exact path="/products/:productId" component={Product} />
            <GuestRoute exact path="/products" component={Products} />
            <GuestRoute exact path="/special-offers" component={() => <Products type="special-offers" />} />
            <GuestRoute exact path="/promotions/:slug" component={() => <Products type="promotions" />} />
            <GuestRoute exact path="/about-us" component={AboutUs} />
            <GuestRoute exact path="/terms-conditions" component={TermsConditions} />
            <GuestRoute exact path="/privacy-policy" component={PrivacyPolicy} />

            <Route path={'/auth' || '/auth/:path?(signin|signup|forgot_password|reset_password/.+|otp_confirmation)'}>
              <Switch>
                {isSignedIn && <Redirect to="/" />}
                <GuestRoute exact path="/auth/signin" component={TabGroup} />
                <GuestRoute exact path="/auth/register" component={TabGroup} />
                <GuestRoute exact path="/auth/forgot_password" component={ForgotPassword} />
                <GuestRoute path="/auth/reset_password/:token" component={ResetPassword} />
                <GuestRoute exact path="/auth/otp_confirmation" component={OTP} />
              </Switch>
            </Route>

            <GuestRoute exact path="/" component={HomePage} />

            <GuestRoute
              path="*"
              component={() => (
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={
                    <Link to="/">
                      <Button type="primary">Back Home</Button>
                    </Link>
                  }
                />
              )}
            />
          </Switch>
        )}
      />
    </DefaultLayout>
  );
};

export default Router;
