import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isSignedInSelector } from 'redux/auth/selectors';
import PropTypes from 'prop-types';

const UserRoute = ({ component: Component, path }) => {
  const isSignedIn = useSelector(isSignedInSelector);
  return (
    <Route
      render={(props) =>
        isSignedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/signin',
            }}
          />
        )
      }
      path={path}
      exact
    />
  );
};
UserRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default UserRoute;
