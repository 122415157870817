import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import selectors from './selectors';
import { fetchProduct, fetchProducts } from 'redux/products/thunks';
import { addToCart, fetchCart } from 'redux/cart/thunks';

export const slice = createSlice({
  name: 'brands',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchBrand.fulfilled, (state, action) => {
        if (!action.payload.brands) return;
        adapter.upsertMany(state, action.payload.brands);
      })
      .addCase(thunks.fetchBrands.fulfilled, (state, action) => {
        if (!action.payload.entities.brands) return;
        adapter.upsertMany(state, action.payload.entities.brands);
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        if (!action.payload.entities.brands) return;
        adapter.upsertMany(state, action.payload.entities.brands);
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        if (!action.payload.entities.brands) return;
        adapter.upsertMany(state, action.payload.entities.brands);
      })
      .addCase(isAnyOf(fetchCart.fulfilled, addToCart.fulfilled), (state, action) => {
        if (!action.payload.brands) return;
        adapter.upsertMany(state, action.payload.brands);
      });
  },
});
const Brands = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Brands;
