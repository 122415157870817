import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import { fetchBranches } from './thunks';

export const slice = createSlice({
  name: 'branches',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      if (!action.payload.branches) return;
      adapter.upsertMany(state, action.payload.branches);
    });
  },
});

const selectors = {
  ...adapter.getSelectors((state) => state['branches']),
};

const Branches = {
  adapter,
  slice,
  selectors,
};
export default Branches;
