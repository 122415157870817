import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Space, Typography, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DetailsItem from '../../DetailsItem';
import ChangePassword from 'screens/ChangePassword';
import EditProfile from 'screens/EditProfile';
import './index.scss';

const AccountTab = () => {
  const { t } = useTranslation('screens');
  const user = useSelector((store) => store.auth.currentUser);
  const [currentView, setCurrentView] = useState('account');
  const location = useLocation();
  const history = useHistory();

  const handleViewChange = (view) => {
    setCurrentView(view);
    if (view === 'changePassword') {
      history.push('/my-account/account/change-password');
    } else if (view === 'editProfile') {
      history.push('/my-account/account/edit-profile');
    } else {
      history.push('/my-account/account');
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('change-password')) {
      setCurrentView('changePassword');
    } else if (path.includes('edit-profile')) {
      setCurrentView('editProfile');
    } else {
      setCurrentView('account');
    }
  }, [location.pathname]);

  return (
    <div className="account-tab">
      {currentView === 'account' && (
        <>
          <Typography className="account-title">{t('my_account.account.title')}</Typography>
          <Space direction="vertical" className="account-data">
            <DetailsItem title={t('my_account.account.first_name')} value={user.first_name} />
            <DetailsItem title={t('my_account.account.last_name')} value={user.last_name} />
            <DetailsItem title={t('my_account.account.email')} value={user.email} />
            <DetailsItem title={t('my_account.account.mobile')} value={user.phone_number} />
            <Row>
              <Button className="account-btns" onClick={() => handleViewChange('changePassword')}>
                {t('my_account.account.change_password')}
              </Button>
              <Button className="account-btns" onClick={() => handleViewChange('editProfile')}>
                {t('my_account.account.edit_profile')}
              </Button>
            </Row>
          </Space>
        </>
      )}

      {currentView === 'changePassword' && <ChangePassword onBack={() => handleViewChange('account')} />}

      {currentView === 'editProfile' && <EditProfile onBack={() => handleViewChange('account')} />}
    </div>
  );
};

export default AccountTab;
