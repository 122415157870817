import { useRef } from 'react';
import { Button, Divider, Modal, Space, Typography, List } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isSignedInSelector } from 'redux/auth/selectors';
import { cartCounter, shippingFees, totalCartPrice, totalCheckoutPrice } from 'redux/cart/selectors';
import localizeNumber from 'utils/localizeNumber';
import { fetchOrders } from 'redux/orders/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import RatingModal from 'components/molecules/RatingModal';
import { cartAdapterSelectors, cartLoading } from 'redux/cart/selectors';
import ProductPreview from 'components/molecules/ProductPreview';

import './index.scss';

const RenderItem = ({ productId }) => {
  return (
    <div className="product-preview">
      <ProductPreview productId={productId} />
    </div>
  );
};

const OrderSummary = ({ isCheckout, setCheckoutStep, showDetails, showTitle, showButton }) => {
  const ratingModalRef = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation('screens');
  const { t: globalTranslation } = useTranslation('global');
  const { t: authenticationTranslation } = useTranslation('authentication');

  const count = useSelector(cartCounter);
  const shippingFeesAmount = useSelector(shippingFees);
  const totalCart = useSelector(totalCartPrice);
  const totalCheckout = useSelector(totalCheckoutPrice);
  const cartItems = useSelector(cartAdapterSelectors.selectIds);
  const loading = useSelector(cartLoading);

  const isSignedIn = useSelector(isSignedInSelector);

  const onPayClick = () => {
    if (isSignedIn) {
      dispatch(fetchOrders({ params: { unrated_only: true }, refresh: true }))
        .then(unwrapResult)
        .then((payload) => {
          if (!isEmpty(payload.result)) {
            ratingModalRef.current?.openModal(payload.result);
          } else if (count !== 0) setCheckoutStep(1);
        });
    } else {
      Modal.confirm({
        title: t('cart.summary.feedback.on_pay_not_logged_in.title'),
        content: t('cart.summary.feedback.on_pay_not_logged_in.content'),
        okText: authenticationTranslation('signup'),
        cancelText: authenticationTranslation('signin'),
        onOk: () => {
          history.push('/auth/signup');
        },
        onCancel: () => {
          history.push('/auth/signin');
        },
      });
    }
  };

  return (
    <div className="order-summary">
      {showDetails && (
        <div className="products-list-wrapper">
          <Typography.Text strong className="products-summary">
            {t('cart.summary.title')}
          </Typography.Text>

          <List
            renderItem={(item, index) => <RenderItem key={item} productId={item} />}
            dataSource={cartItems}
            loading={loading}
          />
        </div>
      )}
      <BorderedSection title={`${!showTitle ? t('cart.summary.title') : ''}`} level={4}>
        <Space size="middle" direction="vertical">
          <Space size="large" direction="horizontal">
            <Typography.Text strong>{t('cart.summary.subtotal', { count })}</Typography.Text>
            <Typography.Text strong>
              {localizeNumber(totalCart || 0)} {globalTranslation('currency')}
            </Typography.Text>
          </Space>
          <Divider />
          {!showButton && shippingFeesAmount >= 0 ? (
            <Space size="large" direction="horizontal">
              <Typography.Text strong>{t('cart.summary.delivery_fees')}</Typography.Text>
              <Typography.Text strong>
                {localizeNumber(shippingFeesAmount)} {globalTranslation('currency')}
              </Typography.Text>
            </Space>
          ) : (
            <Typography.Text type="secondary">{t('cart.summary.delivery_fees_notes')}</Typography.Text>
          )}

          <Divider />
          {!showButton && shippingFeesAmount >= 0 && (
            <Space size="large" direction="horizontal">
              <Typography.Text strong>{t('cart.summary.total_price', { count })}</Typography.Text>
              <Typography.Text strong>
                {localizeNumber(totalCheckout)} {globalTranslation('currency')}
              </Typography.Text>
            </Space>
          )}

          {!isCheckout && showButton && (
            <Space className="summary-action-buttons-wrapper">
              <Button className="summary-action-buttons" onClick={onPayClick} type="primary">
                {t('cart.summary.buttons.proceed-checkout')}
              </Button>
            </Space>
          )}
        </Space>
      </BorderedSection>
      <RatingModal ref={ratingModalRef} />
    </div>
  );
};

export default OrderSummary;
