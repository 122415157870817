export const CityCodesList = [
  '02',
  '03',
  '068',
  '088',
  '097',
  '013',
  '082',
  '045',
  '057',
  '084',
  '064',
  '047',
  '095',
  '046',
  '050',
  '086',
  '048',
  '092',
  '066',
  '096',
  '065',
  '093',
  '062',
  '040',
  '069',
  '055',
];

export const CityCodesOptions = CityCodesList.map((code) => ({ value: code, label: code }));

export const numberTypeOptions = (t) => [
  { label: t('screens:address:form:number_type_options:mobile'), value: 'mobile' },
  { label: t('screens:address:form:number_type_options:home'), value: 'home' },
];
