import { useState } from 'react';
import { Typography, Divider, Button } from 'antd';
import { ReactComponent as DownArrowDarkBlue } from 'assets/icons/DownArrowDarkBlue.svg';
import { ReactComponent as DownArrowlightBlue } from 'assets/icons/DownArrowLightBlue.svg';
import './index.scss';

const CollapsibleSection = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <Typography.Title className="filter-title" level={2}>
        {title}
        <Button
          type="text"
          icon={isCollapsed ? <DownArrowlightBlue /> : <DownArrowDarkBlue />}
          onClick={toggleCollapse}
        />
      </Typography.Title>
      <Divider />
      {!isCollapsed && children}
    </>
  );
};

export default CollapsibleSection;
