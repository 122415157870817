import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import selectors from './selectors';
import { userSignin } from '../auth/thunks';

export const slice = createSlice({
  name: 'users',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addCase(userSignin.fulfilled, (state, action) => {
      if (!action.payload.data.users) return;
      adapter.addMany(state, action.payload.data.users);
    });
    builder.addCase(thunks.editProfile.fulfilled, (state, action) => {
      if (!action.payload.data.users) return;
      adapter.upsertMany(state, action.payload.data.users);
    });
  },
});
const Users = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Users;
