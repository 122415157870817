import { useCallback, useEffect, useState } from 'react';
import { List, Space, Button, Typography } from 'antd';
import AddressItem from '../../molecules/AddressItem';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Addresses from 'redux/addresses';
import { submitOrderAddress } from 'redux/orders/thunks';

import { selectedAddress } from 'redux/cart/selectors';
import { fetchAddresses } from 'redux/addresses/thunks';
import './index.scss';

const AddressesTab = ({ isDetailed, onPickAddress }) => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const addressesIds = useSelector(Addresses.selectors.selectIds);
  const selectedAddressId = useSelector(selectedAddress);
  const order_id = useSelector((state) => state.cart.order_id);

  const dispatch = useDispatch();

  const onAddAddress = useCallback(() => {
    history.push({
      pathname: '/address',
      state: { from: location.pathname },
    });
  }, [history, location.pathname]);

  const handleConfirm = () => {
    setLoading(true);
    if (selectedAddressId) {
      onPickAddress(selectedAddressId);
    }
  };

  useEffect(() => {
    selectedAddressId && dispatch(submitOrderAddress({ address_id: selectedAddressId, order_id }));
  }, [selectedAddressId]);

  const handlePickAddress = useCallback(
    (addressId) => {
      if (onPickAddress) {
        onPickAddress(addressId);
      }
    },
    [onPickAddress],
  );

  const renderItem = useCallback(
    (item) => (
      <div className="addresses-list">
        <AddressItem
          addressId={item}
          isDetailed={isDetailed}
          onPickAddress={handlePickAddress}
          isSelected={item === selectedAddressId}
        />
      </div>
    ),
    [isDetailed, onPickAddress, selectedAddressId],
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAddresses({ params: {} }))
      .then(() => {})
      .finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <div className="address-tab-wrapper">
      {!isDetailed ? (
        <Typography.Title className="select-address" strong>
          {t('cart.select-address')}
        </Typography.Title>
      ) : (
        <Typography.Title className="my-addresses">{t('my_account.addresses.title')}</Typography.Title>
      )}
      <Space direction="vertical" className="address-tab" size="middle">
        <List
          grid={
            isDetailed && {
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4,
            }
          }
          dataSource={addressesIds}
          renderItem={renderItem}
          loading={loading}
        />
        <div className="address-actions">
          <Button className="add-address-btn" onClick={onAddAddress}>
            {t('my_account.addresses.add_address')}
          </Button>
          {!isDetailed && (
            <Button
              className="confirm-btn"
              type="primary"
              onClick={handleConfirm}
              loading={loading}
              disabled={!selectedAddressId}
            >
              {t('my_account.addresses.confirm')}
            </Button>
          )}
        </div>
      </Space>
    </div>
  );
};

export default AddressesTab;
