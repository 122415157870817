import React, { useState } from 'react';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import Modal from 'antd/es/modal';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { ReactComponent as OutlinedHeart } from 'assets/icons/HeartOutlined.svg';
import { ReactComponent as FilledHeart } from 'assets/icons/HeartFilled.svg';
import { useTranslation } from 'react-i18next';
import Products from 'redux/products';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import './AddToWishlist.scss';

const AddToWishlist = ({ product, showText = true }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isAlreadyAdded = product?.favorite;
  const { t } = useTranslation('atoms');

  const onPress = () => {
    if (!isAlreadyAdded) {
      setLoading(true);
      dispatch(Products.thunks.favoriteProduct({ id: product.id }))
        .then(unwrapResult)
        .catch((errors) =>
          Modal.error({
            content: (
              <>
                {errors?.errors?.map((error) => (
                  <Typography.Text key={error}>{error}</Typography.Text>
                ))}
              </>
            ),
          }),
        )
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      dispatch(Products.thunks.unfavoriteProduct({ id: product.id }))
        .then(unwrapResult)
        .catch((error) => Modal.error())
        .finally(() => setLoading(false));
    }
  };
  return (
    <Button
      icon={
        isAlreadyAdded ? showText ? <HeartFilled /> : <FilledHeart /> : showText ? <HeartOutlined /> : <OutlinedHeart />
      }
      type={isAlreadyAdded ? 'default' : 'text'}
      onClick={onPress}
      loading={loading}
      className="add-to-favorites"
    >
      {showText && (isAlreadyAdded ? t('add_to_favorites.button_remove') : t('add_to_favorites.button'))}
    </Button>
  );
};

export default AddToWishlist;
