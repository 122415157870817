import { Col, Row, Typography } from 'antd';
import './index.scss';
const DetailsItem = ({ title, value }) => {
  return (
    <Row className="details-item">
      <Col xs={24} sm={6} m={6} lg={8} xl={8} xxl={6}>
        <Typography.Text strong>{title}</Typography.Text>
      </Col>
      <Col xs={24} sm={8} m={6} lg={8} xl={8} xxl={6}>
        <Typography.Text>{value}</Typography.Text>
      </Col>
    </Row>
  );
};
export default DetailsItem;
