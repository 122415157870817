import { normalize } from 'normalizr';
import { BranchesSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import BranchesAPI from './services';

export const fetchBranches = createPaginatedAsyncThunk('branches/fetchAll', async ({ params }, { extra }) => {
  const response = await BranchesAPI.fetchAll({ params });
  const normalized = normalize(response.data.branches, BranchesSchema.list);
  return extra.withPaginationMeta(response, normalized.entities);
});
