import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { CartOrderSchema } from 'redux/schema';
import CartAPI from './services';

export const fetchCart = createAsyncThunk('cart/fetch', async (_, thunkAPI) => {
  try {
    const response = await CartAPI.fetchCart();
    const normalized = normalize(response.data.order, CartOrderSchema.entity);
    return normalized.entities;
  } catch (error) {
    return thunkAPI.rejectWithValue({ data: error.data, status: error.status });
  }
});

export const addToCart = createAsyncThunk('cart/add', async (data, thunkAPI) => {
  try {
    const response = await CartAPI.updateCart(data);
    const normalized = normalize(response.data.order, CartOrderSchema.entity);
    return normalized.entities;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const removeFromCart = createAsyncThunk('cart/remove', async (data, thunkAPI) => {
  try {
    const response = await CartAPI.updateCart(data);
    const normalized = normalize(response.data.order, CartOrderSchema.entity);
    return normalized.entities;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
