import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Form, Input, Button, Modal, Checkbox } from 'antd';
import entries from 'lodash/entries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { userSignup } from 'redux/auth/thunks';

const Signup = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');
  const [loading, setLoading] = useState(false);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const checkNumber = (_, value) => {
    if (!value || !isNaN(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('global_numbers_validation')));
  };

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(userSignup({ user: values }))
      .then(unwrapResult)
      .then(() => {
        Modal.success({
          title: screenTranslation('signup.success.title'),
          content: screenTranslation('signup.success.content'),
          okText: screenTranslation('signup.success.ok'),
          onOk: () => {
            history.push('/auth/otp_confirmation', { previous: 'signup', phone_number: values.phone_number });
          },
        });
      })
      .catch((_errors) => {
        entries(_errors)?.forEach(([name, errors]) => {
          form.setFields([
            {
              name,
              errors,
            },
          ]);
        });
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  return (
    <div className="form-wrapper">
      <Form name="user" form={form} onFinish={onSubmit}>
        <Col gutter={20}>
          <Form.Item
            rules={[
              { required: true, message: screenTranslation('signup.form.first_name_validation') },
              { min: 2, message: screenTranslation('signup.form.name_min_length') },
            ]}
            colon={false}
            name="first_name"
            label={screenTranslation('signup.form.first_name')}
          >
            <Input placeholder={screenTranslation('signup.form.first_name_placeholder')} />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: screenTranslation('signup.form.last_name_validation') },
              { min: 2, message: screenTranslation('signup.form.name_min_length') },
            ]}
            colon={false}
            name="last_name"
            label={screenTranslation('signup.form.last_name')}
          >
            <Input placeholder={screenTranslation('signup.form.last_name_placeholder')} />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: screenTranslation('signup.form.email_validation') },
              { type: 'email', message: screenTranslation('signup.form.email_wrong_format') },
            ]}
            colon={false}
            name="email"
            label={screenTranslation('signup.form.email')}
          >
            <Input placeholder={screenTranslation('signup.form.email_placeholder')} />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: screenTranslation('signup.form.phone_number_validation') },
              { validator: checkNumber },
            ]}
            colon={false}
            name="phone_number"
            label={screenTranslation('signup.form.phone_number')}
          >
            <Input placeholder={screenTranslation('signup.form.phone_number_placeholder')} />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: screenTranslation('signup.form.password_validation') }]}
            colon={false}
            name="password"
            label={screenTranslation('signup.form.password')}
          >
            <Input.Password placeholder={screenTranslation('signup.form.password_placeholder')} />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: screenTranslation('signup.form.password_confirmation_validation') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(screenTranslation('signup.form.password_confirmation_matching'));
                },
              }),
            ]}
            colon={false}
            name="password_confirmation"
            label={screenTranslation('signup.form.password_confirmation')}
          >
            <Input.Password placeholder={screenTranslation('signup.form.password_confirmation_placeholder')} />
          </Form.Item>
        </Col>
        <Form.Item>
          <Checkbox onChange={(e) => setAgreeChecked(e.target.checked)} className="terms_conditions">
            {screenTranslation('signup.form.i_agree_to_the')}{' '}
            <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
              {screenTranslation('signup.form.terms_and_conditions')}
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} htmlType="submit" type="primary" disabled={!agreeChecked}>
            {t('register_account')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Signup;
