import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import SingleMenu from './components/index.single';

const DropDownMenu = ({ items, children, onClick }) => {
  return (
    <Dropdown overlay={<SingleMenu items={items} localePath="profile-menu" onClick={onClick} />}>{children}</Dropdown>
  );
};
DropDownMenu.protoTypes = {
  items: PropTypes.object,
};
export default DropDownMenu;
