import React from 'react';
import { Typography } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import './index.scss';

const TermsConditions = () => {
  return (
    <BorderedSection
      title="سياسة الاستبدال و الاسترجاع طبقا لقانون حمايه المستهلك:"
      className="terms-conditions-wrapper"
    >
      <Typography.Text>
        خلال 14 يوم من تاريخ الفاتوره : يكون من حق العميل استرجاع المنتج او استبداله بشرط ان تكون بذات الحاله و غير
        مستخدم و الكرتونه سليمه و يكون رد القيمه بنفس طريقة الدفع
        <div>
          خلال 30 يوم من تاريخ الفاتوره : اذا تم استخدام المنتج و اتضح ان به عيب صناعه .. يكون الاستبدال أو الاسترجاع
          بناءا علي تقرير فني بحالة الجهاز من قبل المنتج الرئيسي للسلعة
        </div>
      </Typography.Text>
      <div className="paragraph2"></div>
      <div className="paragraph2" dir="rtl">
        <Typography.Text>
          يرجى تذكر:
          <ul>
            <li>
              يجب أن يكون المنتج ( المنتجات) في عبوته الأصلية وليس مفتوحاً، مع وجود أصل الفاتوره الخاصه بالمنتج
              (المنتجات) ، والملحقات، والهدايا المجانية
            </li>
            <li>.إذا كان العميل قد دفع ببطاقة االائتمان، فسيتم رد القيمة إلى حسابه عن طريق نفس كارت الفيزا</li>
            <li>.إذا كان العميل قد دفع نقداً، فسيتم رد القيمة نقداً</li>
            <li>.لمزيد من المعلومات، نرجو أال تترددوا في االتصال بنا. فنحن نسعد كثيراً بخدمتكم</li>
          </ul>
        </Typography.Text>
      </div>
    </BorderedSection>
  );
};

export default TermsConditions;
