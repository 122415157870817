/*global google*/
import React, { useState, useCallback } from 'react';
import { Button, Input, Tooltip, Typography } from 'antd';
import { AimOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, Autocomplete, OverlayView, useLoadScript } from '@react-google-maps/api';
import { GOOGLE_LIBRARIES, GOOGLE_API_KEY } from './constants';
import './index.scss';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const options = { mapTypeControl: false, zoomControl: false, fullscreenControl: false, streetViewControl: false };

const Map = ({ disableSearch, initialPoint, onValueChange }) => {
  const [autoComplete, setAutoComplete] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const markerPosition = currentPosition || initialPoint;
  const [address, setAddress] = useState(null);
  const { t, i18n } = useTranslation('molecules');
  const { isLoaded } = useLoadScript({
    id: 'script-loader',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: GOOGLE_LIBRARIES,
    language: i18n.language,
  });

  const onLoad = useCallback((autocomplete) => {
    setAutoComplete(autocomplete);
  }, []);

  const updateLocation = useCallback(
    ({ lat, lng }) => {
      setCurrentPosition({ lat, lng });

      let address_components;
      let formatted_address = '';
      const geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({ location: { lat, lng } }, (places, status) => {
        if (status === 'OK') {
          setAddress(places[0].formatted_address);
          address_components = places[0].address_components;
          formatted_address = places[0].formatted_address;
        } else setAddress(t('map.N/A'));
        if (onValueChange) onValueChange({ lat, lng, address_components, formatted_address });
      });
    },
    [onValueChange, t],
  );

  const onSetCurrentLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      updateLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
    });
  }, [updateLocation]);

  const onPlaceChanged = useCallback(() => {
    if (autoComplete !== null && autoComplete.getPlace()?.geometry?.location) {
      setCurrentPosition(autoComplete.getPlace().geometry.location);
      setAddress(autoComplete.getPlace().formatted_address);
      if (onValueChange)
        onValueChange({
          lat: autoComplete.getPlace().geometry.location.lat(),
          lng: autoComplete.getPlace().geometry.location.lng(),
          address_components: autoComplete.getPlace().address_components,
          formatted_address: autoComplete.getPlace().formatted_address,
        });
    }
  }, [autoComplete, onValueChange]);

  const onMarkerDragEnd = useCallback(
    (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      updateLocation({ lat, lng });
    },
    [updateLocation],
  );

  return (
    <div className="map-main-wrapper">
      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={markerPosition} zoom={16} options={options}>
          <Marker
            position={markerPosition}
            onDragEnd={onMarkerDragEnd}
            draggable={!disableSearch}
            language={i18n.language}
          />
          {address && (
            <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={markerPosition}>
              <div className="custom-placeholder">
                <Typography.Text>{address}</Typography.Text>
              </div>
            </OverlayView>
          )}
          {!disableSearch && (
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} className="custom-input">
              <Input type="text" placeholder={t('map.search')} />
            </Autocomplete>
          )}
        </GoogleMap>
      ) : (
        <Typography.Title level={2}>{t('map.loading')}</Typography.Title>
      )}
      <Tooltip title={t('map.current_location')}>
        <Button
          icon={<AimOutlined />}
          className={i18n.language === 'ar' ? 'map-current-location-rtl' : 'map-current-location'}
          onClick={onSetCurrentLocation}
        />
      </Tooltip>
    </div>
  );
};

Map.PropType = {
  disableSearch: PropTypes.bool,
  initialPoint: PropTypes.object,
  onValueChange: PropTypes.func,
};

Map.defaultProps = {
  initialPoint: { lat: 30.0444, lng: 31.2357 },
};

export default React.memo(Map);
