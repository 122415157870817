import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FilterFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Space from 'antd/es/space';
import { useMedia } from 'react-use';
import { unwrapResult } from '@reduxjs/toolkit';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import SideFilter from 'components/molecules/SideFilter';
import ProductsList from 'components/organsims/ProductsList';
import ProductsSort from './components/ProductsSort';
import EntityPagination from 'components/atoms/Pagination';
import { fetchProducts } from 'redux/products/thunks';
import Products from 'redux/products';
import store from 'redux/store';
import useFiltersParams from './hooks/useFiltersParams';
import './index.scss';

const ProductsScreen = ({ type }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { updateFilterParams, getAppliedFilters, resetFilters, isFilteredByBrand } = useFiltersParams({ type });
  const mobileView = useMedia('(max-width: 992px)');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortType, setSortType] = useState('featured');

  const [paginationInfo, setPaginationInfo] = useState({ defaultPageSize: 10, total: 100 });
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const productsList = useSelector(Products.selectors.productsSortedBy)(filteredProducts, sortType);

  const metaUpdate = useCallback(() => {
    const paramsQuery = queryString.stringify(getAppliedFilters());
    const productsMeta = store.getState()._pagination[`products/fetchAll${paramsQuery ? '?' : ''}${paramsQuery}`];

    setPaginationInfo({ defaultPageSize: productsMeta?.per_page, total: productsMeta?.total_entries });
  }, [getAppliedFilters]);

  const toggleModalVisible = useCallback(() => setIsModalVisible(!isModalVisible), [isModalVisible]);

  const onFilterSubmit = useCallback(
    ({ priceRange, brands, category_id }) => {
      updateFilterParams({
        lowest_price: priceRange[0],
        highest_price: priceRange[1],
        category_id,
        brand_id: brands.length > 0 ? brands : queryString.parse(search).brand_id,
      });
      // if (isModalVisible) toggleModalVisible();
      setCurrentPage(1);
    },
    [isModalVisible, toggleModalVisible, updateFilterParams],
  );

  const onSortBy = useCallback(
    (key) => {
      let lowest_to_highest_price = false;
      let highest_to_lowest_price = false;
      let latest_to_oldest = false;
      let featured_first = false;

      switch (key) {
        case 'default':
          lowest_to_highest_price = null;
          highest_to_lowest_price = null;
          latest_to_oldest = null;
          featured_first = null;
          break;
        case 'highest-price':
          highest_to_lowest_price = true;
          break;
        case 'lowest-price':
          lowest_to_highest_price = true;
          break;
        case 'latest':
          latest_to_oldest = true;
          break;
        case 'featured':
          lowest_to_highest_price = null;
          highest_to_lowest_price = null;
          latest_to_oldest = null;
          featured_first = null;

          break;
        default:
          break;
      }
      setCurrentPage(1);
      setSortType(key);
      updateFilterParams({ highest_to_lowest_price, lowest_to_highest_price, latest_to_oldest, featured_first });
      // fetchProductsRequest(1);
    },
    [updateFilterParams],
  );

  const fetchProductsRequest = useCallback(
    (page) => {
      setLoading(true);
      dispatch(
        fetchProducts({
          params: { ...getAppliedFilters(page), per_page: 12 },
          refresh: true,
        }),
      )
        .then(unwrapResult)
        .then((response) => {
          metaUpdate();
          setFilteredProducts(response.result);
          setLoading(false);
        });
    },
    [dispatch, getAppliedFilters, metaUpdate],
  );

  const onPageChange = (page) => {
    setCurrentPage(page);
    fetchProductsRequest(page);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  /**reset filters when search changes */
  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  /**products fetch initially and when filters/search params changes */
  useEffect(() => {
    fetchProductsRequest();
  }, [fetchProductsRequest]);

  return (
    <div className="products-container">
      <Breadcrumbs />
      <Row gutter={40}>
        {!mobileView && (
          <Col xs={0} sm={0} md={6} lg={6} xl={6}>
            <SideFilter
              onFilterSubmit={onFilterSubmit}
              className="side-filter"
              showCategory={isFilteredByBrand()}
              hideBrand={isFilteredByBrand()}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Space className="products-list-wrapper" size="large" direction="vertical">
            <ProductsSort onSortBy={onSortBy} />
            <div>
              <ProductsList products={productsList} loading={loading} />
              <EntityPagination
                current={currentPage}
                defaultPageSize={paginationInfo.defaultPageSize}
                total={paginationInfo.total}
                onChange={onPageChange}
              />
            </div>
          </Space>
        </Col>
      </Row>
      <Button type="primary" icon={<FilterFilled />} className="filter-button" onClick={toggleModalVisible} />
      <Modal
        centered
        visible={isModalVisible && mobileView}
        footer={null}
        onCancel={toggleModalVisible}
        bodyStyle={{ padding: 0 }}
      >
        <SideFilter
          onFilterSubmit={onFilterSubmit}
          showCategory={isFilteredByBrand()}
          hideBrand={isFilteredByBrand()}
        />
      </Modal>
    </div>
  );
};
export default ProductsScreen;
