import { List, Typography, Row, Col, Button } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { ReactComponent as PaymentSuccessIcon } from 'assets/icons/payment-success.svg';
import { useTranslation } from 'react-i18next';
import OrderDetails from './OrderDetails';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cart from 'redux/cart';
import { fetchOrderById } from 'redux/orders/thunks';
import Orders from 'redux/orders';
import { useHistory } from 'react-router';
import Payments from 'redux/payments';
import localizeNumber from 'utils/localizeNumber';

const listGrid = {
  gutter: 20,
  xxs: 1,
  xs: 2,
  sm: 3,
  md: 4,
  lg: 4,
};

const renderItem = (product) => (
  <div className="product-preview">
    <img src={product?.images?.[0]?.versions.medium} alt="product" />
  </div>
);

const PaymentSuccess = () => {
  const { t, i18n } = useTranslation('screens');
  const locale = i18n.language;

  const dispatch = useDispatch();
  const order_id = useSelector((state) => state.payments.order_id);
  const [orderResult, setOrderResult] = useState();
  const order = useSelector((state) => Orders.selectors.selectById(state, orderResult));
  const products = useSelector(Orders.selectors.selectOrderProducts)(orderResult);
  const history = useHistory();

  const getTranslatedPaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return t('payment_success.payment_method.cash');
      case 'online':
        return t('payment_success.payment_method.online');
      case 'visa_on_delivery':
        return t('payment_success.payment_method.visa_on_delivery');
      case 'visa_on_delivery_installments':
        return t('payment_success.payment_method.visa_on_delivery_installments');
      default:
        return t('payment_success.payment_method.unknown');
    }
  };

  const onTrackOrder = useCallback(() => {
    history.push('/my-account/orders');
  }, [history]);

  useEffect(() => {
    if (!order && !order_id) onTrackOrder();
  }, [onTrackOrder, order, order_id]);

  useEffect(() => {
    //remove cart and order_id
    if (order_id)
      dispatch(fetchOrderById({ id: order_id }))
        .then((response) => {
          setOrderResult(response?.payload?.result);
          dispatch(Cart.slice.actions.resetCart());
          dispatch(Payments.slice.actions.resetPayment());
        })
        .catch((error) => console.log(error));
  }, [dispatch, order_id]);

  return (
    <BorderedSection
      type="success"
      title={t('payment_success.description')}
      extraIconTitle={<PaymentSuccessIcon />}
      className="payment"
    >
      <div className="products-details">
        <OrderDetails details={<List grid={listGrid} dataSource={products} renderItem={renderItem} />} />

        {/* removed for now */}
        {/* <OrderDetails
          title={t('payment_success.order_details.delivery_date')}
          details={<Typography.Text>{order?.delivery_date}</Typography.Text>}
        /> */}
      </div>
      <div className="order-details">
        <Col xxs={12} xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <OrderDetails
            title={t('payment_success.order_details.number')}
            details={<Typography.Text>{order?.id}</Typography.Text>}
          />
        </Col>
        <Col xxs={12} xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <OrderDetails
            title={t('payment_success.order_details.date')}
            details={
              <Typography.Text>
                {order?.created_at &&
                  new Intl.DateTimeFormat(locale === 'ar' ? 'ar-EG' : 'en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  }).format(new Date(order.created_at))}
              </Typography.Text>
            }
          />
        </Col>
        <Col xxs={12} xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <OrderDetails
            title={t('payment_success.order_details.total')}
            details={
              <Typography.Text>
                {`${localizeNumber(order?.checkout_total_price)} ${t('payment_success.order_details.currency')}`}
              </Typography.Text>
            }
          />
        </Col>
        <Col xxs={12} xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <OrderDetails
            title={t('payment_success.order_details.payment_method')}
            details={<Typography.Text>{getTranslatedPaymentMethod(order?.payment_method)}</Typography.Text>}
          />
        </Col>
      </div>
      <Row>
        <Col xs={24} sm={24} m={12} lg={12} xl={12} xxl={12} className="button-wrapper">
          <Button className="purchase-history-btn" type="primary" onClick={onTrackOrder}>
            {t('payment_success.order_details.orders-history')}
          </Button>
        </Col>
      </Row>
    </BorderedSection>
  );
};
export default PaymentSuccess;
