import React from 'react';
import { Typography } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import './index.scss';

const PrivacyPolicy = () => {
  return (
    <BorderedSection title="سياسة الخصوصية" className="privacy-policy-wrapper">
      <Typography.Text>آخر تحديث: ٣٠ أكتوبر ٢٠٢٤</Typography.Text>
      <br />
      <Typography.Text>
        مرحبًا بكم في أسواق شارع عبد العزيز.
        <div>
          نحن نقدر خصوصيتك ونلتزم بحماية معلوماتك الشخصية. توضح سياسة الخصوصية هذه كيفية جمعنا لمعلوماتك واستخدامها
          والإفصاح عنها عند زيارة موقعنا الإلكتروني أو إجراء عملية شراء من خلاله{' '}
          <a href="https://www.abdulaziz-stores.com/">https://www.abdulaziz-stores.com/</a>.
        </div>
      </Typography.Text>
      <div className="paragraph" dir="rtl">
        <h5>المعلومات التي نجمعها</h5>
        <Typography.Text>
          نجمع أنواعًا مختلفة من المعلومات عند تفاعلك مع موقعنا، وتشمل:
          <ul>
            <li>
              <b>المعلومات الشخصية:</b> قد تتضمن اسمك، وعنوان الفواتير والشحن، وعنوان البريد الإلكتروني، ورقم الهاتف،
              ومعلومات الدفع، وغيرها من المعلومات التي تقدمها لنا طوعًا.
            </li>
            <li>
              <b>معلومات الطلبات: </b>تفاصيل حول عمليات الشراء وطرق الدفع وسجل المعاملات.
            </li>
            <li>
              <b>المعلومات التقنية:</b>عنوان IP ونوع المتصفح ومعلومات عن الجهاز وبيانات حول كيفية تفاعلك مع موقعنا، ويتم
              جمعها تلقائيًا عبر ملفات تعريف الارتباط (cookies) والتقنيات المشابهة.
            </li>
          </ul>
        </Typography.Text>
      </div>
      <div className="paragraph" dir="rtl">
        <h5>كيفية استخدامنا لمعلوماتك</h5>
        <Typography.Text>
          نستخدم معلوماتك بطرق متعددة لتحسين تجربتك على موقعنا، بما في ذلك:
          <ul>
            <li>
              <b>تنفيذ الطلبات: </b> لمعالجة وتنفيذ وشحن طلباتك وإدارة المرتجعات.
            </li>
            <li>
              <b>خدمة العملاء:</b> للرد على استفساراتك وطلباتك وتلبية احتياجات الدعم.
            </li>
            <li>
              <b>التسويق والعروض الترويجية:</b>لإرسال العروض الترويجية والنشرات الإخبارية والمعلومات حول المنتجات أو
              الخدمات التي قد تهمك (يمكنك إلغاء الاشتراك في أي وقت).
            </li>
            <li>
              <b>التحليل والتحسينات:</b>لتحسين وظائف موقعنا واختيار المنتجات وتجربة المستخدم.
            </li>
          </ul>
        </Typography.Text>
      </div>
      <div className="paragraph" dir="rtl">
        <h5>كيفية مشاركة معلوماتك</h5>
        <Typography.Text>
          قد نشارك معلوماتك مع أطراف ثالثة، ولكن فقط في الظروف التالية:
          <ul>
            <li>
              <b>مقدمو الخدمات: </b>لمساعدتنا في تنفيذ الطلبات، ومعالجة المدفوعات، وتنفيذ الحملات التسويقية.
            </li>
            <li>
              <b>نقل الأعمال:</b>في حالة الاندماج أو الاستحواذ أو بيع الأصول، قد يتم نقل معلوماتك.
            </li>
            <li>
              <b>المتطلبات القانونية:</b> عندما يقتضي القانون ذلك، للامتثال لأمر قضائي أو عملية قانونية مشابهة أو لحماية
              حقوقنا وسلامة مستخدمينا.
            </li>
          </ul>
        </Typography.Text>
      </div>
      <div className="paragraph" dir="rtl">
        <h5>ملفات تعريف الارتباط وتقنيات التتبع</h5>
        <Typography.Text>
          يستخدم موقعنا ملفات تعريف الارتباط (cookies) والتقنيات المشابهة لتوفير تجربة أفضل، وتذكر التفضيلات، وتتبع
          النشاط على موقعنا. يمكنك التحكم في ملفات تعريف الارتباط من خلال إعدادات المتصفح، مع ملاحظة أن بعض الوظائف قد
          تتأثر إذا تم تعطيل ملفات تعريف الارتباط.
        </Typography.Text>
      </div>
      <div className="paragraph" dir="rtl">
        <h5>الاحتفاظ بالبيانات</h5>
        <Typography.Text>
          سنحتفظ بمعلوماتك طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في هذه السياسة، ما لم يُطلب منا قانونيًا
          الاحتفاظ بها لفترة أطول.
        </Typography.Text>
      </div>
      <div className="paragraph" dir="rtl">
        <h5>حقوقك</h5>
        <Typography.Text>
          حسب موقعك، قد تكون لديك حقوق تتعلق بمعلوماتك الشخصية، وتشمل:
          <ul>
            <li>الحق في الوصول إلى بياناتك الشخصية أو تحديثها.</li>
            <li>الحق في طلب حذف البيانات أو تقييد معالجتها.</li>
            <li>الحق في الاعتراض على معالجة البيانات أو طلب نقل البيانات..</li>
          </ul>
        </Typography.Text>
      </div>
      <div className="paragraph" dir="rtl">
        <h5>تغييرات على سياسة الخصوصية هذه</h5>
        <Typography.Text>
          قد نقوم بتحديث سياسة الخصوصية هذه لتعكس التغييرات في ممارساتنا. سنقوم بإخطارك بأي تغييرات هامة عن طريق نشر
          السياسة الجديدة في هذه الصفحة مع تاريخ "آخر تحديث" الجديد.
        </Typography.Text>
      </div>
    </BorderedSection>
  );
};

export default PrivacyPolicy;
