import { normalize } from 'normalizr';
import { InstallmentsSchema } from 'redux/schema';
import createPaginatedAsyncThunk from 'redux/utils/createPaginatedAsyncThunk';
import InstallmentsAPI from './services';

export const fetchInstallments = createPaginatedAsyncThunk('installments/fetchAll', async ({ params }, { extra }) => {
  const response = await InstallmentsAPI.fetchAll({ params });
  const normalized = normalize(response.data.bank_installments, InstallmentsSchema.list);
  return extra.withPaginationMeta(response, normalized.entities);
});
