import axios from 'axios';

const fetchAll = (params) =>
  axios.get('v1/products', {
    params,
  });

const fetchUserProducts = (params) =>
  axios.get('v1/users/products', {
    params,
  });

const fetchById = (id) => axios.get(`v1/products/${id}`);
const fetchUserProductById = (id) => axios.get(`v1/users/products/${id}`);

const addToFavorite = (id) => axios.post(`/v1/users/products/${id}/favorites`);
const removeFromFavorite = (id) => axios.delete(`/v1/users/products/${id}/favorites`);

const ProductsAPI = {
  fetchAll,
  fetchById,
  fetchUserProductById,
  fetchUserProducts,
  addToFavorite,
  removeFromFavorite,
};

export default ProductsAPI;
