import { useEffect, useMemo, useState } from 'react';
import { useWindowScroll } from 'react-use';
import useMedia from 'react-use/lib/useMedia';
import './index.scss';

const HideController = ({ children, move = '-55px', toBack }) => {
  const [hide, setHide] = useState(false);
  const { y } = useWindowScroll();
  const mobileView = useMedia('(max-width: 768px)');
  const toBackClass = toBack ? 'to-back' : '';

  useEffect(() => {
    if (y > 100) setHide(true);
    else setHide(false);
  }, [y]);

  let classHide = hide ? 'hide' : '';

  const styles = useMemo(() => {
    if (hide && !mobileView) return { transform: `translate(0, ${move})` };
    else return {};
  }, [hide, mobileView, move]);

  return (
    <div className={`hide-controller ${classHide} ${toBackClass}`} style={styles}>
      {children}
    </div>
  );
};

export default HideController;
